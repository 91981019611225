import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.ijeaweleinvest.com', // Adjust base URL as needed
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const fetchUserData = async () => {
  const response = await api.get('/users/current_user/');
  return response.data;
};

export const uploadProfileImage = async (userId, imageFile) => {
  const formData = new FormData();
  formData.append('profile_image', imageFile);
  const response = await api.patch(`/users/${userId}/`, formData);
  return response.data;
};
