import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout";
import InvestmentBanner from "../Components/InvestmentBanner";
import "../Styles/AssetDetails.css";
import LoadingDot from "../Components/LoadingDot";
import axios from "axios";

function AssetDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [asset, setAsset] = useState(null);
  const [userId, setUserId] = useState(null);
  const [balance, setBalance] = useState(0);
  const [investmentValue, setInvestmentValue] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [contractTerms, setContractTerms] = useState(""); // Store contract HTML
  const [payoutInterval, setPayoutInterval] = useState(""); 
  const [agreedToContract, setAgreedToContract] = useState(false); // Agreement checkbox state
  const [isLoading, setIsLoading] = useState(false); //to track whether the agreement terms are still loading.
  const [showContractOverlay, setShowContractOverlay] = useState(false); // Contract overlay visibility

  // Fecth current user personal details details
   useEffect(() => {
    //fetch user details
    const fetchUserDetails = async () => {
      try {
        const token  = localStorage.getItem('token');
        if (!token) {
          console.error('No authentication found. Please log in');
        }

        const response = await fetch(
          "https://api.ijeaweleinvest.com/users/current_user/",
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          }
        );

        if (!response.ok) {
          console.error(`Error: ${response.status}`);
          return
        }

        const data = await response.json();
        setUserId(data.id);
        setBalance(data.wallet.balance);
      } catch (err) {}
    };

    fetchUserDetails()
   }, []); //

  // Fetch the asset details by ID
  useEffect(() => {
    const fetchAsset = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("No auth token found. Please log in.");

        const response = await axios.get(`https://api.ijeaweleinvest.com/asset/${id}`, {
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        });
        setAsset(response.data);

      } catch (error) {
        console.error("Error fetching asset details", error);
      }
    };

    fetchAsset();
  }, [id]);

  // Fetch contract terms for the selected asset
  const fetchContractTerms = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`https://api.ijeaweleinvest.com/asset/${id}/get_contract/`, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      });
      setContractTerms(response.data.contract);
      setShowContractOverlay(true); // Show overlay after fetching terms
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching contract terms:", error);
      setError("Unable to load contract terms. Please try again.");
    }
  };

  // Handle confirmation of investment
  const handleConfirmInvestment = () => {
    setIsLoading(true);
    if (balance < asset.value) {
      setError("Insufficient balance. Please fund your wallet.");
      setTimeout(() => setError(""), 3000);
      setIsLoading(false);
      return;
    }
    setInvestmentValue(asset.value);
    fetchContractTerms(); // Fetch contract terms when user confirms investment
  };

  // Handle actual investment after confirmation
  const handleContinueInvestment = async () => {
    if (!payoutInterval) {
      setError("Please select a payout interval.");
      return;
    }
    if (!agreedToContract) {
      setError("You must agree to the contract terms to proceed.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `https://api.ijeaweleinvest.com/asset/${id}/invest/`,
        {
          user: userId,
          asset_id: id,
          amount_invested: asset.value,
          payout_interval: payoutInterval,
          agreed_to_contract: true,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSuccess(true); // Show success message after successful investment

      setTimeout(() => {
        navigate("/myinvestment");
      }, 2000);
    } catch (error) {
      console.error("Error during investment", error);
      setError("There was an issue processing your investment. Please try again.");
      setTimeout(() => setError(""), 3000);
    }
  };

  // Handle user closing the success message
  const handleReset = () => {
    setSuccess(false);
    setInvestmentValue(null);
  };

  if (!asset) {
    return <LoadingDot />;
  }

  return (
    <Layout>
      <div className="asset-details-container">
      <div className="asset-details">
          <div className="asset-date">
            <h1 className="asset-name">{asset.name}</h1>
            <div className="asset-end-start">
              <p className="start">Asset details:</p>
            </div>
          </div>

          <div className="asset-details-info">
            <img src={asset.image} alt={asset.name} className="asset-image" />
            <div className="asset-financials">
              <div className="asset-financials-details">
                <p>
                  Total Payout (asset value + ROI):{" "}
                  <span>₦{asset.total_payout.toLocaleString()}</span>
                </p>
                <p>
                  Return on Investment (ROI): <span>{asset.roi}%</span>
                </p>
                <p>
                  Asset Value: <span>₦{asset.value.toLocaleString()}</span>
                </p>
                <p>
                  Hire Purchase Duration: <span>{asset.duration} weeks</span>
                </p>
              </div>

              {/* Confirm Investment Button */}
              <div className="confirm-investment">
                <button onClick={handleConfirmInvestment} disabled={isLoading}>
                  {isLoading ? 'Loading...' : 'Confirm Investment'}
                </button>
              </div>
              {error && <p className="error-message">{error}</p>}
            </div>
          </div>
        </div>
        
        
        <InvestmentBanner />

        {/* Contract and Payout Selection Overlay */}
        {showContractOverlay && (
          <div className="confirmation-overlay">
            <div className="confirmation-card">
              <h3>Investment Agreement</h3>
              <div className="contract-terms" dangerouslySetInnerHTML={{ __html: contractTerms }} />

              {/* Payout Interval Selection */}
              <label htmlFor="payoutInterval">Choose Payout Interval:</label>
              <select
                id="payoutInterval"
                value={payoutInterval}
                onChange={(e) => setPayoutInterval(e.target.value)}
              >
                <option value="">Select Interval</option>
                <option value="week">Weekly</option>
                <option value="month">Monthly</option>
                <option value="year">Yearly</option>
              </select>

              {/* Agreement Checkbox */}
              <label className="agreement-checkbox">
                <input
                  type="checkbox"
                  checked={agreedToContract}
                  onChange={(e) => setAgreedToContract(e.target.checked)}
                />
                I agree to the terms of the contract.
              </label>

              <button onClick={handleContinueInvestment} className="cont-invest-agreement">Continue</button>
              <button onClick={() => setShowContractOverlay(false)} className="cancel-invest-agreement">Cancel</button>
              {error && <p className="error-message">{error}</p>}
            </div>
          </div>
        )}

        {/* Success Message */}
        {success && (
          <div className="success-message">
            <h3>Investment Successful!</h3>
            <p>Your investment of ₦{investmentValue} has been successfully processed.</p>
            <button onClick={handleReset}>OK</button>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default AssetDetails;
