import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../Components/Layout"; // Wrap in Layout
import "../Styles/MyInvestment.css";
// import Corolla from "../Assets/corolla-le.png"

const MyInvestments = () => {
  const [activeTab, setActiveTab] = useState("active"); // active or completed
  const [activeInvestments, setActiveInvestments] = useState([]); // Active investments
  const [completedInvestments, setCompletedInvestments] = useState([]); // Completed investments
  const [userId, setUserId] = useState(null);

  // Fetch the user's investment data and user ID
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");

        const userResponse = await axios.get(
          // "http://38.242.226.193:8000/users/current_user/",
          "https://api.ijeaweleinvest.com/users/current_user/",
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const userData = userResponse.data;
        setUserId(userData.id);

        const investmentResponse = await axios.get(
          // "http://38.242.226.193:8000/users/investments/",
          "https://api.ijeaweleinvest.com/users/investments/",
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // Separate active and completed investments
        const { active_investments, completed_investments } =
          investmentResponse.data;
        setActiveInvestments(active_investments || []);
        setCompletedInvestments(completed_investments || []);
        // console.log(investmentResponse.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchUserData();
  }, []);

  // Function to handle tab change (active/completed)
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Render investment details
  const renderInvestments = (investments) => {
    return investments.length > 0 ? (
      investments.map((investment) => (
        <div className="investment-card" key={investment.asset.id}>
          <h3>{investment.asset.name}</h3>
          {/* vehicle image */}
          {investment.asset.image && (
            <img
              // src={Corolla}
              src={investment.asset.image}
              alt={`${investment.asset.name}`}
              className="asset-image"
            />
          )}
          <p>
            Amount Invested:{" "}
            <span className="detail-value">₦{investment.amount_invested.toLocaleString()}</span>
          </p>
          <p>
            Total Payout:{" "}
            <span className="detail-value">₦{investment.payout_balance.toLocaleString()}</span>
          </p>
          <p>
            ROI: <span className="detail-value">{investment.asset.roi}%</span>
          </p>
          <p>
            Duration:{" "}
            <span className="detail-value">{investment.asset.duration}</span>{" "}
            weeks
          </p>
          <p>
            Interval payout amount: {" "}
            <span className="detail-value">₦{investment.interval_payout_amount.toLocaleString()}</span>
          </p>

          {/* Optional collapsible description with asset vehicle image */}
          <details>
            <summary className="view-more-det">View More Details</summary>
            <p>
              Start Date:{" "}
              <span className="detail-value">{investment.start_date}</span>
            </p>
            <p>
              End Date:{" "}
              <span className="detail-value">{investment.end_date}</span>
            </p>
            <p>
              Description:{" "}
              <span className="detail-value">
                {investment.asset.description}
              </span>
            </p>
            <p>
              Profit:{" "}
              <span className="detail-value">₦{investment.asset.profit.toLocaleString()}</span>
            </p>
          </details>
        </div>
      ))
    ) : (
      <p className="no-investments">No {activeTab} investments found.</p>
    );
  };

  return (
    <Layout>
      {" "}
      {/* Wrapped in Layout */}
      <div className="my-investments-page">
        <h1>My Investments</h1>

        {/* Filter Buttons */}
        <div className="investment-filters">
          <button
            className={`filter-btn ${activeTab === "active" ? "active" : ""}`}
            onClick={() => handleTabChange("active")}
            style={{
              backgroundColor: activeTab === "active" ? "#ffc700" : "black",
              color: activeTab === "active" ? "black" : "white",
            }}
          >
            Active Investments
          </button>
          <button
            className={`filter-btn ${
              activeTab === "completed" ? "active" : ""
            }`}
            onClick={() => handleTabChange("completed")}
            style={{
              backgroundColor: activeTab === "completed" ? "#ffc700" : "black",
              color: activeTab === "completed" ? "black" : "white",
            }}
          >
            Completed Investments
          </button>
        </div>

        {/* Display filtered investments */}
        <div className="investment-cards">
          {activeTab === "active"
            ? renderInvestments(activeInvestments)
            : renderInvestments(completedInvestments)}
        </div>
      </div>
    </Layout>
  );
};

export default MyInvestments;
