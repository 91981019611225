import React, { useState, Suspense, lazy, useEffect } from "react";
import { CSSTransition } from "react-transition-group"; 
import Layout from "../Components/Layout";
import PersonalInfo from "../Components/PersonalInfo";
import Security from "../Components/Security";
import KycVerification from "../Components/KycVerification";
import "../Styles/Profile.css";

function Profile() {
  const [activeTab, setActiveTab] = useState("personal");
  const [isUploading, setIsUploading] = useState(false);

  // Tab navigation handler
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Layout>
      {/* {isUploading && (
        <div className="overlay">
          <div className="loader"></div>
        </div>
      )} */}
      <div className="profile-container">
        {/* Tabs */}
        <div className="profile-tabs">
          <button
            className={`tab ${activeTab === "personal" ? "active-tab" : ""}`}
            onClick={() => handleTabClick("personal")}
          >
            Personal Info
          </button>
          <button
            className={`tab ${activeTab === "kyc" ? "active-tab" : ""}`}
            onClick={() => handleTabClick("kyc")}
          >
            KYC Verification
          </button>
          <button
            className={`tab ${activeTab === "security" ? "active-tab" : ""}`}
            onClick={() => handleTabClick("security")}
          >
            Password & Security
          </button>
        </div>
          
        {/* Render Tabs Conditionally */}
        <Suspense fallback={<div>Loading...</div>}>
          {activeTab === "personal" && <PersonalInfo />}
          {activeTab === "kyc" && <KycVerification />}
          {activeTab === "security" && <Security />}
        </Suspense>
      </div>
    </Layout>
  );
}

export default Profile;
