import React from "react";
import PasswordReset from "./PasswordReset";
import PinReset from "./PinReset";
import "../Styles/Security.css";

const Security = () => {
  return (
    <div className="security-page">
      <h4>Security Settings</h4>
      <p>Manage your account security</p>
      <div className="accordion">
        <PasswordReset />
        <PinReset />
      </div>
    </div>
  );
};

export default Security;
