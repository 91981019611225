// import React, {useState} from 'react'
// import ProfileImagePlaceholder from '../Assets/picture-profile-icon.jpg';
// import { FaCamera } from "react-icons/fa"; // camera icon
// import "../Styles/ProfilePicture.css";


// function ProfilePicture({userId}) {
//   const [profileImage, setProfileImage] = useState(ProfileImagePlaceholder);
//   const [isUploading, setIsUploading] = useState(false);


//   //   // Handle profile image change
//   const handleImageChange = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setIsUploading(true);
//       const formData = new FormData();
//       formData.append("profile_image", file);

//       try {
//         const token = localStorage.getItem("token");

//         const response = await fetch(
//           `https://api.ijeaweleinvest.com/users/${userId}/`,
//           {
//             method: "PATCH",
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//             body: formData,
//           }
//         );

//         if (response.ok) {
//           const updatedData = await response.json();
//           setProfileImage(updatedData.profile_image); // Update profile image
//         } else {
//           console.error("Failed to upload image");
//         }
//       } catch (error) {
//         console.error("Error uploading image:", error);
//       } finally {
//         setIsUploading(false);
//       }
//     }
//   };


//   return (
//     <div className="profile-picture">
//         <img src={profileImage} alt="Profile" />
//         <div className="camera-btn">
//           <label htmlFor="profileImageUpload">
//             <FaCamera />
//             {/* Upload */}
//           </label>
//           <input
//             id="profileImageUpload"
//             type="file"
//             accept="image/*"
//             onChange={handleImageChange}
//             style={{ display: "none" }}
//           />
//         </div>
//       </div>
//   )
// }

// export default ProfilePicture


// import React, { useState, useEffect } from "react";
// import ProfileImageDisplay from "./ProfileImageDisplay";
// import ProfileImageUpload from "./ProfileImageUpload";
// import LoadingIndicator from "./LoadingIndicator";
// import { fetchUserData, uploadProfileImage } from "../Utilities/apiService"
// import ProfileImagePlaceholder from "../Assets/picture-profile-icon.jpg";
// import "../Styles/ProfilePicture.css";

// function ProfilePicture() {
//   const [profileImage, setProfileImage] = useState(localStorage.getItem("profileImage") || ProfileImagePlaceholder);
//   const [isUploading, setIsUploading] = useState(false);
//   const [userId, setUserId] = useState(null);
//   const [previewImage, setPreviewImage] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       setIsUploading(true);
//       try {
//         const data = await fetchUserData();
//         setUserId(data.id);
//         if (data.profile_image) {
//           setProfileImage(data.profile_image);
//           localStorage.setItem("profileImage", data.profile_image);
//         }
//       } catch (err) {
//         setError("Failed to fetch user data. Please try again.");
//       } finally {
//         setIsUploading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   const handleImageChange = async (e) => {
//     const file = e.target.files[0];
//     if (file && validateImage(file)) {
//       const previewUrl = URL.createObjectURL(file);
//       setPreviewImage(previewUrl);
//       setIsUploading(true);
//       try {
//         const updatedData = await uploadProfileImage(userId, file);
//         if (updatedData.profile_image) {
//           setProfileImage(updatedData.profile_image);
//           localStorage.setItem("profileImage", updatedData.profile_image);
//           setPreviewImage(null);
//         } else {
//           throw new Error("No image URL returned from the server.");
//         }
//       } catch (err) {
//         setError("Failed to upload image. Please try again.");
//       } finally {
//         setIsUploading(false);
//       }
//     }
//   };

//   const validateImage = (file) => {
//     const validTypes = ["image/jpeg", "image/png", "image/gif"];
//     const maxSize = 5 * 1024 * 1024; // 5MB
//     if (!validTypes.includes(file.type)) {
//       setError("Invalid file type. Only JPEG, PNG, and GIF are allowed.");
//       return false;
//     }
//     if (file.size > maxSize) {
//       setError("File size exceeds 5MB. Please upload a smaller image.");
//       return false;
//     }
//     setError(null);
//     return true;
//   };

//   return (
//     <div className="profile-picture">
//       {isUploading ? (
//         <LoadingIndicator />
//       ) : (
//         <ProfileImageDisplay
//           profileImage={profileImage}
//           previewImage={previewImage}
//           onError={(e) => {
//             e.target.onerror = null;
//             e.target.src = ProfileImagePlaceholder;
//           }}
//         />
//       )}
//       <ProfileImageUpload handleImageChange={handleImageChange} />
//       {error && <div className="error-message">{error}</div>}
//     </div>
//   );
// }

// export default ProfilePicture;


import React, { useState, useEffect } from "react";
import ProfileImageDisplay from "./ProfileImageDisplay";
import ProfileImageUpload from "./ProfileImageUpload";
import LoadingIndicator from "./LoadingIndicator";
import { fetchUserData, uploadProfileImage } from "../Utilities/apiService";
import ProfileImagePlaceholder from "../Assets/picture-profile-icon.jpg";
import "../Styles/ProfilePicture.css";

function ProfilePicture() {
  const [profileImage, setProfileImage] = useState(localStorage.getItem("profileImage") || ProfileImagePlaceholder);
  const [isUploading, setIsUploading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsUploading(true);
      try {
        const data = await fetchUserData();
        setUserId(data.id);
        const imageUrl = data.profile_image ? `https://api.ijeaweleinvest.com${data.profile_image}` : ProfileImagePlaceholder;
        setProfileImage(imageUrl);
        if (data.profile_image) {
          localStorage.setItem("profileImage", imageUrl);
        }
      } catch (err) {
        setError("Failed to fetch user data. Please try again.");
      } finally {
        setIsUploading(false);
      }
    };
    fetchData();
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && validateImage(file)) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewImage(previewUrl);
      setIsUploading(true);
      try {
        const updatedData = await uploadProfileImage(userId, file);
        if (updatedData.profile_image) {
          const imageUrl = `https://api.ijeaweleinvest.com${updatedData.profile_image}`;
          setProfileImage(imageUrl);
          localStorage.setItem("profileImage", imageUrl);
          setPreviewImage(null);
        } else {
          throw new Error("No image URL returned from the server.");
        }
      } catch (err) {
        setError("Failed to upload image. Please try again.");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const validateImage = (file) => {
    const validTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (!validTypes.includes(file.type)) {
      setError("Invalid file type. Only JPEG, PNG, and GIF are allowed.");
      return false;
    }
    if (file.size > maxSize) {
      setError("File size exceeds 5MB. Please upload a smaller image.");
      return false;
    }
    setError(null);
    return true;
  };

  return (
    <div className="profile-picture">
      {isUploading ? (
        <LoadingIndicator />
      ) : (
        <ProfileImageDisplay
          profileImage={profileImage}
          previewImage={previewImage}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = ProfileImagePlaceholder;
          }}
        />
      )}
      <ProfileImageUpload handleImageChange={handleImageChange} />
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default ProfilePicture;
