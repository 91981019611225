
function TextInput({ label, id, type, placeholder, required, icon, value, onChange, style, error }) {
  return (
    <div className="text-input-container">
      <label htmlFor={id}>
        {label} {required && <span className="asterisk">*</span>}
      </label>
      <br />
      <input
        type={type}
        id={id}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        style={style}
      />
      {icon && <span className="icon">{icon}</span>}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default TextInput;
