import "../Styles/InvestmentBanner.css";

const InvestmentBanner = () => {
  return (
    <div className="investment-banner">
      <h3>
        <span className="banner-percent">up to 40%</span> Profit <br /> Every 3 months
      </h3>
    </div>
  );
};

export default InvestmentBanner;
