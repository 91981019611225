// import React, { useState } from "react";
// // from vehicle database
// import vehicleData from "../Utilities/VehicleData";
// import "../Styles/VehicleList.css";

// function VehicleList() {
//   const [showAll, setShowAll] = useState(false);

//   const defaultVehicleCount = 4;

//   const vehicleToShow = showAll
//     ? vehicleData
//     : vehicleData.slice(0, defaultVehicleCount);

//   const handleViewMore = () => {
//     setShowAll(!showAll); 
//   };

//   return (
//     <>
//       {/* "view more" button */}
//       {vehicleData.length > defaultVehicleCount && (
//         <div className="show-more-button-container">
//           <button onClick={handleViewMore} className="view-more-button">
//             {showAll ? "View Less" : "View More"}
//             {/* Change button text based on state */}
//           </button>
//         </div>
//       )}
//       <div className="vehicle-list">
//         {/* vehicle */}
//         {vehicleToShow.map(({ id, name, type, image }) => (
//           <div key={id} className="vehicle-card">
//             <div className="vehicle-info">
//               <h2>{name}</h2>
//               {/* <p>{type}</p> */}
//             </div>
//             <div className="vehicle-img">
//               <img src={image} alt={name} className="vehicle-image" />
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* "view more" button */}
//       {vehicleData.length > defaultVehicleCount && (
//         <div className="show-more-button-container">
//           <button onClick={handleViewMore} className="view-more-button">
//             {showAll ? "View Less" : "View More"}
//             {/* Change button text based on state */}
//           </button>
//         </div>
//       )}
//     </>
//   );
// }

// export default VehicleList;



// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Link } from 'react-router-dom';
// import "../Styles/VehicleList.css";

// function VehicleList() {
//   const [showAll, setShowAll] = useState(false); // State to manage whether to show all vehicles
//   const [vehicles, setVehicles] = useState([]); // State to store fetched vehicles
//   const [loading, setLoading] = useState(true); // Loading state
//   const [error, setError] = useState(null);     // Error state

//   const defaultVehicleCount = 4; // Display 8 vehicles initially

//   // Function to fetch vehicles from the backend
//   const fetchVehicles = async () => {
//     try {
//       // Retrieve token from localStorage (or another storage mechanism)
//       const token = localStorage.getItem("token");

//       // Set up headers with the token
//       const config = {
//         headers: {
//           Authorization: `Bearer ${token}`, // Assuming Bearer token
//         },
//       };

//       // Fetch vehicle data from the backend
//       const response = await axios.get("https://api.ijeaweleinvest.com/asset/", config);

//       // Store fetched vehicles in state
//       setVehicles(response.data);
//       setLoading(false); // Stop loading when data is fetched
//     } catch (error) {
//       console.error("Error fetching vehicles:", error);
//       setError(error.message); // Set error if request fails
//       setLoading(false);
//     }
//   };

//   // Fetch vehicles when the component mounts
//   useEffect(() => {
//     fetchVehicles();
//   }, []);

//   // Toggle the display of all or limited vehicles
//   const handleViewMore = () => {
//     setShowAll(!showAll);
//   };

//   // Determine the number of vehicles to show based on the `showAll` state
//   const vehiclesToShow = showAll ? vehicles : vehicles.slice(0, defaultVehicleCount);

//   if (loading) {
//     return <div>Loading...</div>; // Show loading state
//   }

//   if (error) {
//     return <div>Error: {error}</div>; // Display error if fetch fails
//   }

//   return (
//     <>
//       {/* "view more" button */}
//       {vehicles.length > defaultVehicleCount && (
//         <div className="show-more-button-container">
//           <button onClick={handleViewMore} className="view-more-button">
//             {showAll ? "View Less" : "View More"}
//             {/* Change button text based on state */}
//           </button>
//         </div>
//       )}

//       {/* Vehicle list */}
//       <div className="vehicle-list">
//         {vehiclesToShow.map(({ id, name, type, image }) => (
//           <div key={id} className="vehicle-card">
//             <div className="vehicle-info">
//               <h2>{name}</h2>
//             </div>
//             <div className="vehicle-img">
//               <img src={image} alt={name} className="vehicle-image" />
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* "view more" button at the bottom */}
//       {vehicles.length > defaultVehicleCount && (
//         <div className="show-more-button-container">
//           <button onClick={handleViewMore} className="view-more-button">
//             {showAll ? "View Less" : "View More"}
//           </button>
//         </div>
//       )}
//     </>
//   );
// }

// export default VehicleList;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'; // Import Link for navigation
import "../Styles/VehicleList.css";

function VehicleList() {
  const [showAll, setShowAll] = useState(false); // State to manage whether to show all vehicles
  const [vehicles, setVehicles] = useState([]); // State to store fetched vehicles
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null);     // Error state

  const defaultVehicleCount = 4; // Display 4 vehicles initially

  // Function to fetch vehicles from the backend
  const fetchVehicles = async () => {
    try {
      // Retrieve token from localStorage (or another storage mechanism)
      const token = localStorage.getItem("token");

      // Set up headers with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Assuming Bearer token
        },
      };

      // Fetch vehicle data from the backend
      const response = await axios.get("https://api.ijeaweleinvest.com/asset/", config);

      // Store fetched vehicles in state
      setVehicles(response.data);
      setLoading(false); // Stop loading when data is fetched
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      setError(error.message); // Set error if request fails
      setLoading(false);
    }
  };

  // Fetch vehicles when the component mounts
  useEffect(() => {
    fetchVehicles();
  }, []);

  // Toggle the display of all or limited vehicles
  const handleViewMore = () => {
    setShowAll(!showAll);
  };

  // Determine the number of vehicles to show based on the `showAll` state
  const vehiclesToShow = showAll ? vehicles : vehicles.slice(0, defaultVehicleCount);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error if fetch fails
  }

  return (
    <>
      {/* "view more" button */}
      {vehicles.length > defaultVehicleCount && (
        <div className="show-more-button-container">
          <button onClick={handleViewMore} className="view-more-button">
            {showAll ? "View Less" : "View More"}
          </button>
        </div>
      )}

      {/* Vehicle list */}
      <div className="vehicle-list">
        {vehiclesToShow.map(({ id, name, type, image }) => (
          // Wrap each vehicle card with Link component to navigate to asset details page
          <Link key={id} to={`/asset/${id}`} className="vehicle-card-link">
            <div className="vehicle-card">
              <div className="vehicle-info">
                <h2>{name}</h2>
                <p>Hire Purchase</p>
              </div>
              <div className="vehicle-img">
                <img src={image} alt={name} className="vehicle-image" />
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* "view more" button at the bottom */}
      {vehicles.length > defaultVehicleCount && (
        <div className="show-more-button-container">
          <button onClick={handleViewMore} className="view-more-button">
            {showAll ? "View Less" : "View More"}
          </button>
        </div>
      )}
    </>
  );
}

export default VehicleList;




