import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import BalanceOverview from "../Components/BalanceOverview";
import InvestmentBanner from "../Components/InvestmentBanner";
import VehicleList from "../Components/VehicleList";
import { CSSTransition } from "react-transition-group"; //for the transition effect
import "../Styles/Overview.css";

function Overview() {
  const [inProp, setInProp] = useState(false); //for animation
  const overviewContainerRef = useRef(null);
  const vehiclesSectionRef = useRef(null);
  const investmentBannerRef = useRef(null);

  useEffect(() => {
    //animation effect
    setInProp(true);
  }, []);

  return (
    <Layout>
      <CSSTransition
        in={inProp}
        timeout={1000}
        classNames="fade"
        nodeRef={overviewContainerRef}
      >
        <div ref={overviewContainerRef} className="overview-container">
          <BalanceOverview />
          <CSSTransition
            in={inProp}
            timeout={1200}
            classNames="fade"
            nodeRef={vehiclesSectionRef}
          >
            <div ref={vehiclesSectionRef} className="vehicles-section">
              <VehicleList />
            </div>
          </CSSTransition>
          {/* <CSSTransition in={inProp} timeout={1500} classNames="fade" nodeRef={investmentBannerRef}>
            <InvestmentBanner ref={investmentBannerRef} />
          </CSSTransition> */}
          <CSSTransition
            in={inProp}
            timeout={1500}
            classNames="fade"
            nodeRef={investmentBannerRef}
          >
            <div ref={investmentBannerRef}>
              <InvestmentBanner />
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
    </Layout>
  );
}

export default Overview;
