import React from 'react';
import ProfileImagePlaceholder from '../Assets/picture-profile-icon.jpg';

const ProfileImageDisplay = ({ profileImage, previewImage, onError }) => {
  return (
    <img
      src={previewImage || profileImage}
      alt="Profile"
      onError={onError}
    />
  );
};

export default ProfileImageDisplay;
