import React from 'react';
import { FaCamera } from 'react-icons/fa';

const ProfileImageUpload = ({ handleImageChange }) => {
  return (
    <div className="camera-btn">
      <label htmlFor="profileImageUpload">
        <FaCamera />
      </label>
      <input
        id="profileImageUpload"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default ProfileImageUpload;
