// import React, { useState, useEffect, useRef } from 'react';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
// import Layout from '../Components/Layout';
// import axios from 'axios';
// import AssetCard from '../Components/AssetCards';
// import "../Styles/Assets.css";

// const Assets = () => {
//   const [activeTab, setActiveTab] = useState('active');
//   const [assets, setAssets] = useState([]);
//   const [error, setError] = useState(null);
//   const [inProp, setInProp] = useState(false);

//   // Refs for each animated section
//   const titleRef = useRef(null);
//   const filterButtonsRef = useRef(null);
//   const gridRef = useRef(null);

//   useEffect(() => {
//     setInProp(true); // Trigger animations on component mount

//     axios.get('http://38.242.226.193:8000/asset/')
//       .then((response) => {
//         setAssets(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching assets:', error);
//         setError(error);
//       });
//   }, []);

//   if (error) {
//     return <div>Error fetching assets: {error.message}</div>;
//   }

//   return (
//     <Layout>
//       <div className="assets-page">
//         {/* Title Animation */}
//         <CSSTransition
//           in={inProp}
//           timeout={500}
//           classNames="fade"
//           nodeRef={titleRef} // Attach ref
//         >
//           <h1 ref={titleRef}>Assets</h1>
//         </CSSTransition>

//         {/* Filter Buttons Animation */}
//         <CSSTransition
//           in={inProp}
//           timeout={700}
//           classNames="fade"
//           nodeRef={filterButtonsRef} // Attach ref
//         >

//           {/* asset filter buttons to be deleted */}
//           <div ref={filterButtonsRef} className="assets-filter">
//             <button
//               className={activeTab === 'active' ? 'active' : ''}
//               onClick={() => setActiveTab('active')}
//             >
//               Active
//             </button>
//             <button
//               className={activeTab === 'completed' ? 'completed' : ''}
//               onClick={() => setActiveTab('completed')}
//             >
//               Completed
//             </button>
//           </div>
//         </CSSTransition>

//         {/* Asset Cards Animation */}
//         <CSSTransition
//           in={inProp}
//           timeout={900}
//           classNames="fade"
//           nodeRef={gridRef} // Attach ref
//         >
//           <div ref={gridRef} className="assets-grid">
//             <TransitionGroup>
//               {assets
//                 .filter((asset) => asset.status === activeTab)
//                 .map((asset) => {
//                   const cardRef = React.createRef(); // Create a ref for each card
//                   return (
//                     <CSSTransition
//                       key={asset.id}
//                       timeout={1000}
//                       classNames="fade"
//                       nodeRef={cardRef} // Attach ref to each card
//                     >
//                       <div ref={cardRef}>
//                         <AssetCard asset={asset} />
//                       </div>
//                     </CSSTransition>
//                   );
//                 })}
//             </TransitionGroup>
//           </div>
//         </CSSTransition>
//       </div>
//     </Layout>
//   );
// };

// export default Assets;

import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Layout from "../Components/Layout";
import axios from "axios";
import AssetCard from "../Components/AssetCards";
import "../Styles/Assets.css";

const Assets = () => {
  const [assets, setAssets] = useState([]);
  const [error, setError] = useState(null);
  const [inProp, setInProp] = useState(false);

  // Refs for animations
  const titleRef = useRef(null);
  const gridRef = useRef(null);

  // Fetch assets from the backend
  // useEffect(() => {
  //   setInProp(true); // Trigger animations on component mount
  //   // axios.get('http://38.242.226.193:8000/asset/')
  //   axios
  //     .get("https://api.ijeaweleinvest.com/asset/")
  //     .then((response) => {
  //       setAssets(response.data); // Store fetched assets
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching assets:", error);
  //       setError(error); // Store the error if there's one
  //     });
  // }, []);
  useEffect(() => {
    setInProp(true);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Assuming it's a Bearer token
      },
    };

    // Make the authenticated API request
    axios
      .get("https://api.ijeaweleinvest.com/asset/", config) // Pass config as the second argument
      .then((response) => {
        setAssets(response.data); // Store fetched assets
      })
      .catch((error) => {
        console.error("Error fetching assets:", error);
        setError(error); // Store the error if there's one
      });
  }, []);

  if (error) {
    return <div>Error fetching assets: {error.message}</div>;
  }

  return (
    <Layout>
      <div className="assets-page">
        {/* Title Animation */}
        <CSSTransition
          in={inProp}
          timeout={500}
          classNames="fade"
          nodeRef={titleRef}
        >
          <h1 ref={titleRef}>Assets</h1>
        </CSSTransition>

        {/* Assets Grid */}
        <div className="assets-grid" ref={gridRef}>
          {assets.map((asset) => (
            <CSSTransition
              key={asset.id}
              timeout={1000}
              classNames="fade"
              in={inProp}
              appear
            >
              <AssetCard asset={asset} />
            </CSSTransition>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Assets;
