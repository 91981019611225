import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // import useNavigate for routing
import TextInput from "../Components/TextInput";
import axios from "axios"; // import axios for API calls
import { RevolvingDot } from "react-loader-spinner";
import "../Styles/SignUp.css";

function OTPVerification() {
  const [isAnimated, setIsAnimated] = useState(false); // state to control animation
  const [isLoading, setIsLoading] = useState(false); // loading effect state
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  // Fetch email from localStorage and redirect to signup if missing
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    } else {
      navigate("/signup"); // Redirect to signup if email is missing
    }
  }, [navigate]);

  // page animation
  useEffect(() => {
    setIsAnimated(true);
  }, []);

  // handle OTP verification
  const handleOTPVerification = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear previous error message

    const oTPVerificationData = {
      email: email,
      otp: otp,
    };

    try {
      setIsLoading(true); // Show loading indicator
      const response = await axios.post(
        "https://api.ijeaweleinvest.com/users/verify-email/",
        oTPVerificationData
      );

      if (response.status === 200) {
        setIsLoading(false);
        localStorage.removeItem("email"); // Clear email from localStorage after successful verification
        navigate("/signin"); // Redirect to signin page
      }
    } catch (error) {
      setIsLoading(false); // Hide loading indicator
      // handle OTP verification error
      if (error.response && error.response.status === 404) {
        setErrorMessage("OTP not found. Please check your email for the correct OTP.");
      } else {
        setErrorMessage("An error occurred, please try again later.");
      }
    }
  };

  return (
    <div className="signup-container no-topnav-page">
      <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
        <h1 className="hello-text">Hello</h1>
        <h2 className="welcome-text">Almost Done! Complete Your Registration</h2>
      </div>

      <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
        <div className="form-card">
          <h2>OTP Verification</h2>
          <p>Verify Your Account: Enter OTP Sent to Your Email</p>

          <form onSubmit={handleOTPVerification}>
            <TextInput
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="OTP"
              required
            />
            <TextInput
              label="Email"
              type="email"
              value={email}
              readOnly // Make email field read-only since it's pre-filled
              placeholder="Email"
            />

            {/* Show error message if there's an issue */}
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            {/* Show loading indicator */}
            {isLoading && (
              <RevolvingDot
                type="RevolvingDot"
                color="#00BFFF"
                height={5}
                width={5}
                timeout={3000} // 3 seconds
              />
            )}

            <button type="submit">Continue</button>
          </form>

          <p className="create-account-text">
            Head back to Sign In? <Link to="/signin">Click Here</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default OTPVerification;
