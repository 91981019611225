import React, { useState } from "react";
import TextInput from "./TextInput";

function AddressInput({ address, setAddress, userId }) {
  const [isAddressButtonVisible, setIsAddressButtonVisible] = useState(false);

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    setIsAddressButtonVisible(e.target.value ? true : false);
  };

    const submitAddress = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await fetch(
          `https://api.ijeaweleinvest.com/users/${userId}/`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ address }), // Send address to API
          }
        );

        if (response.ok) {
          alert("Address updated successfully!");
          setIsAddressButtonVisible(false); // Hide button after success
        } else {
          const errorData = await response.json();
          alert(`Failed to update address: ${errorData.detail}`);
        }
      } catch (error) {
        console.error("Error updating address:", error);
        alert("An error occurred while updating your address.");
      }
    };

  return (
    <div>
      <TextInput
        label="Address"
        type="text"
        value={address}
        placeholder="Enter address here"
        onChange={handleAddressChange}
      />
      {isAddressButtonVisible && (
        <button className="submit-address-btn" onClick={submitAddress}>
          Submit Address
        </button>
      )}
    </div>
  );
}

export default AddressInput;
