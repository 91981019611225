import React, { useState, useCallback, useEffect } from "react";
import { IoMdEyeOff } from "react-icons/io";
import { FaEye } from "react-icons/fa";

function PasswordInput({ label, id, placeholder, required, value, onChange, style, error }) {
  // states
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [inputType, setInputType] = useState("password");

  const handleEyeClick = useCallback(() => {
    setPasswordVisibility(!passwordVisibility);
  }, [passwordVisibility]);

  useEffect(() => {
    setInputType(passwordVisibility ? "text" : "password");
  }, [passwordVisibility]);

  return (
    <div className="password-input-container">
      <label htmlFor={id}>
        {label} <span className="asterisk">*</span>
      </label>
      <br />
      <input
        type={inputType}
        id={id}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        style={style}
      />
      <span className="eye-icon" onClick={handleEyeClick}>
        {!passwordVisibility ? <IoMdEyeOff /> : <FaEye />}
      </span>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default PasswordInput;
