import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Styles/Referral.css"; // Create and link this CSS file for custom styles
import { FaCopy, FaShareAlt } from "react-icons/fa"; // Icons for copy and share buttons

function Referral() {
  const [referralLink, setReferralLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);

  // Fetch the referral link when the component mounts
  useEffect(() => {
    const fetchReferralLink = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.ijeaweleinvest.com/users/get-referral-link/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setReferralLink(response.data.referral_link);
      } catch (error) {
        console.error("Error fetching referral link:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReferralLink();
  }, []);

  // Copy referral link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset success message after 2 seconds
    });
  };

  // Placeholder for share functionality
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: "Referral Link",
        text: "Join me on IjeaweleInvest with this link!",
        url: referralLink,
      }).catch((error) => console.error("Error sharing:", error));
    } else {
      alert("Sharing is not supported on this device.");
    }
  };

  return (
    <div className="referral-container">
      <h2>Your Referral Link</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="referral-box">
          <p className="referral-link">{referralLink}</p>
          <button className="copy-button" onClick={handleCopyLink}>
            <FaCopy /> {copySuccess ? "Copied!" : "Copy Link"}
          </button>
          <button className="share-button" onClick={handleShare}>
            <FaShareAlt /> Share Link
          </button>
        </div>
      )}
    </div>
  );
}

export default Referral;
