import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import TextInput from "../Components/TextInput";
import PasswordInput from "../Components/PasswordInput";
// import Loader from 'react-loader-spinner'; // for the loading effect
import { RevolvingDot } from "react-loader-spinner";
import "../Styles/SignUp.css";

function SignIn() {
  const [isAnimated, setIsAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(false); //loading effect state

  // Page animation
  useEffect(() => {
    setIsAnimated(true);
  }, []);

  // This will redirect authenticated user to dashboard automatically
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/overview");
    }
  }, []);

  // Form state for email and password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // Used to navigate on successful login

  // Sign in form validation and submission
async function validateSignIn(e) {
  e.preventDefault();
  setIsLoading(true); 
  setErrorMessage(""); // Clear previous errors

  // Validate email and password
  const emailRegex = /^\w+@\w+\.\w+$/;
  if (!emailRegex.test(email)) {
    setErrorMessage("Please enter a valid email address.");
    setIsLoading(false);
    return;
  }
  if (password.length < 8) {
    setErrorMessage("Password must be at least 8 characters long.");
    setIsLoading(false);
    return;
  }

  // Prepare login data
  const loginData = { username: email, password };

  try {
    // Send login request
    const response = await axios.post(
      "https://api.ijeaweleinvest.com/api/auth/token/",
      loginData
    );

    if (response.status === 200) {
      setIsLoading(false);
      localStorage.setItem("token", response.data.access);
      navigate("/overview");
    }
  } catch (error) {
    setIsLoading(false);

    // Error handling based on the type
    if (error.response) {
      if (error.response.status === 400 || error.response.status === 401) {
        setErrorMessage("Invalid email or password. Please try again.");
      } else if (error.response.status >= 500) {
        setErrorMessage("Server error. Please try again later.");
      }
    } else if (error.request) {
      setErrorMessage("Network error. Check your connection and try again.");
    } else {
      setErrorMessage("An unexpected error occurred. Please try again.");
    }

    console.error("Sign-in error:", error.message);
  }
}

  // async function validateSignIn(e) {
  //   e.preventDefault();
  //   setIsLoading(true); // Show loader

  //   // Simple form validation
  //   const emailRegex = /^\w+@\w+\.\w+$/;
  //   if (!emailRegex.test(email)) {
  //     setErrorMessage("Please enter a valid email address");
  //     return;
  //   }
  //   if (password.length < 8) {
  //     setErrorMessage("Password must be at least 8 characters long");
  //     return;
  //   }

  //   // Create login data object
  //   const loginData = {
  //     username: email,
  //     password: password,
  //   };

  //   try {
  //     // Send login request to the backend API
  //     const response = await axios.post(
  //       // "http://38.242.226.193:8000/api/auth/token/",
  //       "https://api.ijeaweleinvest.com/api/auth/token/",
  //       loginData
  //     );
      
  //     // If login is successful, redirect to the dashboard (overview)
  //     if (response.status === 200) {
  //       setIsLoading(false); // Hide loader on error
  //       const token = response.data.access;
  //       localStorage.setItem("token", token); // Save token in localStorage
  //       navigate("/overview");
  //     }
  //   } catch (error) {
  //     setIsLoading(false); // Hide loader on error
  //     // Handle login error
  //     if (error.response && error.response.status === 400) {
  //       setErrorMessage("Invalid email or password. Please try again.");
  //     } else {
  //       setErrorMessage("An error occurred. Please try again later.");
  //     }
  //   }
  // }

  return (
    <div className="signup-container no-topnav-page">
      <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
        <h1 className="hello-text">Hello</h1>
        <h2 className="welcome-text">
          Login to your <br /> Ijeawele Account
        </h2>
      </div>
      <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
        <div className="form-card">
          <h2>Sign In</h2>
          <p>Welcome back to Ijeawele Investments</p>
          <form onSubmit={validateSignIn}>
            <TextInput
              label="Email"
              type="email"
              id="email"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <PasswordInput
              id="password"
              placeholder="Enter your password"
              label="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Link to="/forgotpassword" className="forgot-password">
              Forgot Password?
            </Link>

            {/* Show error message if there's an issue */}
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            {/* page loading indication */}
            {isLoading && (
              <RevolvingDot
                type="RevolvingDot"
                color="#00BFFF"
                height={5}
                width={5}
                timeout={3000} //3 secs
              />
            )}

            <button type="submit">Sign In</button>
          </form>

          <p className="create-account-text">
            Are you new to Ijeawele? <Link to="/">Create an Account</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
