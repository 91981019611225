import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../Styles/Sidebar.css";
import Logo from "../Assets/logo.svg";
import Earth from "../Assets/earth.png";
import ElevationRise from "../Assets/elevation-rise.svg.png";
import InvestNow from "../Assets/invest-now.png";
import LogoutIcon from "../Assets/logoutIcon.png";
import ProfileIcon from "../Assets/profileIcon.png";
import StackOfMoney from "../Assets/stackOfMoney.png";

function Sidebar({ isVisible }) {
  // Use the useLocation hook gets the current route path
  const location = useLocation();

  // Determines which link is active based on current path
  const isActive = (path) => {
    // Set "/overview" as the default route if no match is found
    if (location.pathname === "/") {
      return path === "/overview";
    }
    return location.pathname === path;
  };

  return (
    <div className={`sidebar ${isVisible ? "visible" : "hidden"}`}>
      <div className="logo">
        <img src={Logo} alt="Ijeawele Logo" />
      </div>

      {/* overview link */}
      <nav className="nav-menu">
        <ul>
          <li>
            <Link
              to="/overview"
              className={`nav-link ${
                isActive("/overview") ? "active-link" : ""
              }`}
            >
              <span className="icon">
                <img src={Earth} alt="Earth Icon" />
              </span>
              Overview
            </Link>
          </li>
          {/* Transactions link */}
          <li>
            <Link
              to="/transactions"
              className={`nav-link ${
                isActive("/transactions") ? "active-link" : ""
              }`}
            >
              <span className="icon">
                <img src={ElevationRise} alt="Elevation Rise Icon" />
              </span>
              Transactions
            </Link>
          </li>
          {/* assets link */}
          <li>
            <Link
              to="/assets"
              className={`nav-link ${isActive("/assets") ? "active-link" : ""}`}
            >
              <span className="icon">
                <img src={StackOfMoney} alt="Stack of Money Icon" />
              </span>
              Assets
            </Link>
          </li>
          {/* investments link*/}
          <li>
            <Link
              to="/myinvestment"
              className={`nav-link ${
                isActive("/myinvestment") ? "active-link" : ""
              }`}
            >
              <span className="icon">
                <img src={ElevationRise} alt="Elevation Rise Icon" />
              </span>
              My Investments
            </Link>
          </li>
          {/* profile link */}
          <li>
            <Link
              to="/profile"
              className={`nav-link ${
                isActive("/profile") ? "active-link" : ""
              }`}
            >
              <span className="icon">
                <img src={ProfileIcon} alt="Profile Icon" />
              </span>
              Profile
            </Link>
          </li>
          {/* log out link */}
          <li>
            <Link
              to="/logout"
              className={`nav-link ${isActive("/logout") ? "active-link" : ""}`}
            >
              <span className="icon">
                <img src={LogoutIcon} alt="Logout Icon" />
              </span>
              Log out
            </Link>
          </li>
        </ul>
      </nav>

      {/* invest now link */}
      <div className="invest-cta">
        <span>
          <img src={InvestNow} alt="Invest Now Icon" />
        </span>
        <h4>Invest</h4>
        <p>Would you like to make an investment?</p>
        <Link to="/investment">
          <button>Invest now</button>
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;
