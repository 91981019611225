

// import { jwtDecode } from "jwt-decode"; // Correct import for version 4.x and above
import { jwtDecode } from 'jwt-decode';

// Function to get the token from local storage
export const getToken = () => {
  return localStorage.getItem('token');
};

/**
 * Checks if the token is expired.
 * @param {string} token - The JWT token.
 * @returns {boolean} - Returns true if the token is valid, false if expired or invalid.
 */
export const isTokenValid = (token) => {
  if (!token) return false;

  try {
    const { exp } = jwtDecode(token);
    // Check if the expiration time has passed (exp is in seconds, Date.now() returns ms)
    return exp * 1000 > Date.now();
  } catch (error) {
    // If there's an error in decoding, assume the token is invalid
    return false;
  }
};
