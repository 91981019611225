// import React, { useState, useEffect } from "react";
// import "../Styles/KycVerification.css";
// import axios from "axios";

// const KycVerification = () => {
//   const [idType, setIdType] = useState("");
//   const [idNumber, setIdNumber] = useState("");
//   const [idFile, setIdFile] = useState(null);
//   const [idFilePreview, setIdFilePreview] = useState(null);
//   const [faceFile, setFaceFile] = useState(null);
//   const [faceFilePreview, setFaceFilePreview] = useState(null);
//   const [step, setStep] = useState(1);
//   const [loading, setLoading] = useState(false);
//   const [message, setMessage] = useState(null);
//   const [profileId, setProfileId] = useState(null);
//   const [userId, setUserId] = useState(null);
//   const [verificationStatus, setVerificationStatus] = useState(false);

//   // States for Next of Kin details
//   const [nextOfKin, setNextOfKin] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     address: "",
//     relationship: "",
//   });

//    // Fetch profile and verification status on component mount
//   useEffect(() => {
//     const fetchUserProfile = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         if (!token) {
//           setMessage("Authentication error. Please log in again.");
//           return;
//         }

//         const response = await axios.get(
//           "https://api.ijeaweleinvest.com/users/current_user/",
//           { headers: { Authorization: `Bearer ${token}` } }
//         );

//         setProfileId(response.data.user);
//         setUserId(response.data.id);
//         setVerificationStatus(response.data.verification_status);
//       } catch (error) {
//         setMessage("Failed to fetch user profile. Please try again.");
//       }
//     };

//     fetchUserProfile();
//   }, []);

//    // Handle file input change and preview
//   const handleIdFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     if (selectedFile) {
//       setIdFile(selectedFile);
//       setIdFilePreview(URL.createObjectURL(selectedFile));
//     }
//   };

//    // Handle file input change and preview
//   const handleFaceFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     if (selectedFile) {
//       setFaceFile(selectedFile);
//       setFaceFilePreview(URL.createObjectURL(selectedFile));
//     }
//   };

//   // Handle ID snapshot submission
//   const handleIdSubmit = async () => {
//     if (!idFile || !faceFile) {
//       setMessage("Please upload both an ID image and a facial snapshot.");
//       return;
//     }

//     if (!profileId) {
//       setMessage("Failed to fetch user profile. Please refresh and try again.");
//       return;
//     }

//     setLoading(true);
//     try {
//       const formData = new FormData();
//       formData.append("identity_card", idFile);
//       formData.append("facial_recognition", faceFile); // Facial recognition image
//       formData.append("user", profileId);
//       formData.append("kyc_verification_status", "In Progress");

//       const token = localStorage.getItem("token");
//       if (!token) {
//         setMessage("Authentication error. Please log in again.");
//         setLoading(false);
//         return;
//       }

//       await axios.post("https://api.ijeaweleinvest.com/kyc/", formData, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       setStep(2);
//       setMessage(null);
//     } catch (error) {
//       setMessage("Failed to submit KYC images. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Handle next of KIN submission
//    const handleNextOfKinSubmit = async () => {
//     const { firstName, lastName, email, address, relationship } = nextOfKin;
//     if (!firstName || !lastName || !email || !address || !relationship) {
//       setMessage("Please fill in all Next of Kin details.");
//       return;
//     }

//     setLoading(true);
//     try {
//       const token = localStorage.getItem("token");

//       if (!token) {
//         setMessage("Authentication error. Please log in again.");
//         setLoading(false);
//         return;
//       }

//       const payload = {
//         profile: userId,
//         first_name: firstName,
//         last_name: lastName,
//         address,
//         email,
//         relationship,
//       };

//       await axios.post("https://api.ijeaweleinvest.com/next-of-kin/", payload, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       setMessage(
//         "KYC verification request submitted! Your ID card is being reviewed."
//       );
//     } catch (error) {
//       setMessage("Failed to submit Next of Kin details. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // if user is verified, disabled KYC Verification
//   if (verificationStatus) {
//     return (
//       <div className="kyc-container">
//         <h4>KYC Verification</h4>
//         <p className="kyc-message">
//           You are already verified. KYC is disabled.
//         </p>
//       </div>
//     );
//   }

//   return (
//     <div className="kyc-container">
//       <div className="kyc-header">
//         <h4>KYC Verification</h4>
//         <div className="kyc-steps">
//           <div className={`step ${step >= 1 ? "completed" : ""}`}>
//             1. Identity Verification
//           </div>
//           <div className={`step ${step === 2 ? "active" : ""}`}>
//             2. Next Of Kin
//           </div>
//         </div>
//       </div>

//       {step === 1 && (
//         <div className="kyc-form">
//           <h5>Identity Verification</h5>
//           <div className="kyc-upload">
//             <label htmlFor="id-upload" className="kyc-upload-label">
//               Upload your Government ID card
//             </label>
//             <input
//               type="file"
//               id="id-upload"
//               className="kyc-upload-input"
//               accept="image/*"
//               onChange={handleIdFileChange}
//             />
//             {idFilePreview && (
//               <div className="image-preview">
//                 <img src={idFilePreview} alt="ID Preview" />
//                 <p>ID image selected</p>
//               </div>
//             )}
//           </div>

//           <div className="kyc-upload">
//             <label htmlFor="face-upload" className="kyc-upload-label">
//               {/* Capture a snapshot holding your ID */}
//               Add a clear photo of your face holding the ID uploaded above.
//             </label>
//             <input
//               type="file"
//               id="face-upload"
//               className="kyc-upload-input"
//               accept="image/*"
//               onChange={handleFaceFileChange}
//             />
//             {faceFilePreview && (
//               <div className="image-preview">
//                 <img src={faceFilePreview} alt="Face Preview" />
//                 <p>Facial image selected</p>
//               </div>
//             )}
//           </div>

//           <button onClick={handleIdSubmit} className="id-submit-button">
//             {loading ? "Submitting..." : "Next"}
//           </button>
//         </div>
//       )}

//       {step === 2 && (
//         <div className="kyc-form">
//           <h5>Next of Kin Information</h5>
//           <input
//             type="text"
//             placeholder="First Name"
//             value={nextOfKin.firstName}
//             onChange={(e) =>
//               setNextOfKin({ ...nextOfKin, firstName: e.target.value })
//             }
//           />
//           <input
//             type="text"
//             placeholder="Last Name"
//             value={nextOfKin.lastName}
//             onChange={(e) =>
//               setNextOfKin({ ...nextOfKin, lastName: e.target.value })
//             }
//           />
//           <input
//             type="email"
//             placeholder="Email"
//             value={nextOfKin.email}
//             onChange={(e) =>
//               setNextOfKin({ ...nextOfKin, email: e.target.value })
//             }
//           />
//           <input
//             type="text"
//             placeholder="Address"
//             value={nextOfKin.address}
//             onChange={(e) =>
//               setNextOfKin({ ...nextOfKin, address: e.target.value })
//             }
//           />
//           <input
//             type="text"
//             placeholder="Relationship"
//             value={nextOfKin.relationship}
//             onChange={(e) =>
//               setNextOfKin({ ...nextOfKin, relationship: e.target.value })
//             }
//           />

//           <button onClick={handleNextOfKinSubmit} className="id-submit-button">
//             {loading ? "Submitting..." : "Submit"}
//           </button>
//         </div>
//       )} 

//       {message && <div className="kyc-message">{message}</div>}
//     </div>
//   );
// };

// export default KycVerification;




import React, { useState, useEffect } from "react";
import "../Styles/KycVerification.css";
import axios from "axios";

const KycVerification = () => {
  const [idType, setIdType] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [idFile, setIdFile] = useState(null);
  const [idFilePreview, setIdFilePreview] = useState(null);
  const [faceFile, setFaceFile] = useState(null);
  const [faceFilePreview, setFaceFilePreview] = useState(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [profileId, setProfileId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [pendingMessage, setPendingMessage] = useState(""); // State for "Verification in Progress" message

  // States for Next of Kin details
  const [nextOfKin, setNextOfKin] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    relationship: "",
  });

  // Fetch profile and verification status on component mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setMessage("Authentication error. Please log in again.");
          return;
        }

        const response = await axios.get(
          "https://api.ijeaweleinvest.com/users/current_user/",
          { headers: { Authorization: `Bearer ${token}` } }
        );

        setProfileId(response.data.user);
        setUserId(response.data.id);
        setVerificationStatus(response.data.verification_status);
        if (response.data.verification_status === "In Progress") {
          setPendingMessage("Verification in Progress");
        }
      } catch (error) {
        setMessage("Failed to fetch user profile. Please try again.");
      }
    };

    fetchUserProfile();
  }, []);

  // Handle file input change and preview
  const handleIdFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setIdFile(selectedFile);
      setIdFilePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleFaceFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFaceFile(selectedFile);
      setFaceFilePreview(URL.createObjectURL(selectedFile));
    }
  };

  // Handle ID snapshot submission
  const handleIdSubmit = async () => {
    if (!idFile || !faceFile) {
      setMessage("Please upload both an ID image and a facial snapshot.");
      return;
    }

    if (!profileId) {
      setMessage("Failed to fetch user profile. Please refresh and try again.");
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("identity_card", idFile);
      formData.append("facial_recognition", faceFile); 
      formData.append("user", profileId);
      formData.append("kyc_verification_status", "In Progress");

      const token = localStorage.getItem("token");
      if (!token) {
        setMessage("Authentication error. Please log in again.");
        setLoading(false);
        return;
      }

      const response = await axios.post("https://api.ijeaweleinvest.com/kyc/", formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setPendingMessage(response.data.message || "Verification in Progress");
      setStep(2);
      setMessage(null);
    } catch (error) {
      setMessage("Failed to submit KYC images. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle Next of Kin submission
  const handleNextOfKinSubmit = async () => {
    const { firstName, lastName, email, address, relationship } = nextOfKin;
    if (!firstName || !lastName || !email || !address || !relationship) {
      setMessage("Please fill in all Next of Kin details.");
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        setMessage("Authentication error. Please log in again.");
        setLoading(false);
        return;
      }

      const payload = {
        profile: userId,
        first_name: firstName,
        last_name: lastName,
        address,
        email,
        relationship,
      };

      const response = await axios.post("https://api.ijeaweleinvest.com/next-of-kin/", payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setPendingMessage(response.data.message || "Verification in Progress");
      setMessage(null); // Clear the message after submission success
    } catch (error) {
      setMessage("Failed to submit Next of Kin details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // If user is already verified, disable KYC verification
  if (verificationStatus) {
    return (
      <div className="kyc-container">
        <h4>KYC Verification</h4>
        <p className="kyc-message">
          You are already verified. KYC is disabled.
        </p>
      </div>
    );
  }

  return (
    <div className="kyc-container">
      <div className="kyc-header">
        <h4>KYC Verification</h4>
        <div className="kyc-steps">
          <div className={`step ${step >= 1 ? "completed" : ""}`}>
            1. Identity Verification
          </div>
          <div className={`step ${step === 2 ? "active" : ""}`}>
            2. Next Of Kin
          </div>
        </div>
      </div>

      {step === 1 && (
        <div className="kyc-form">
          <h5>Identity Verification</h5>
          <div className="kyc-upload">
            <label htmlFor="id-upload" className="kyc-upload-label">
              Upload your Government ID card
            </label>
            <input
              type="file"
              id="id-upload"
              className="kyc-upload-input"
              accept="image/*"
              onChange={handleIdFileChange}
            />
            {idFilePreview && (
              <div className="image-preview">
                <img src={idFilePreview} alt="ID Preview" />
                <p>ID image selected</p>
              </div>
            )}
          </div>

          <div className="kyc-upload">
            <label htmlFor="face-upload" className="kyc-upload-label">
              Add a clear photo of your face holding the ID uploaded above.
            </label>
            <input
              type="file"
              id="face-upload"
              className="kyc-upload-input"
              accept="image/*"
              onChange={handleFaceFileChange}
            />
            {faceFilePreview && (
              <div className="image-preview">
                <img src={faceFilePreview} alt="Face Preview" />
                <p>Facial image selected</p>
              </div>
            )}
          </div>

          <button onClick={handleIdSubmit} className="id-submit-button">
            {loading ? "Submitting..." : "Next"}
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="kyc-form">
          <h5>Next of Kin Information</h5>
          <input
            type="text"
            placeholder="First Name"
            value={nextOfKin.firstName}
            onChange={(e) =>
              setNextOfKin({ ...nextOfKin, firstName: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Last Name"
            value={nextOfKin.lastName}
            onChange={(e) =>
              setNextOfKin({ ...nextOfKin, lastName: e.target.value })
            }
          />
          <input
            type="email"
            placeholder="Email"
            value={nextOfKin.email}
            onChange={(e) =>
              setNextOfKin({ ...nextOfKin, email: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Address"
            value={nextOfKin.address}
            onChange={(e) =>
              setNextOfKin({ ...nextOfKin, address: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Relationship"
            value={nextOfKin.relationship}
            onChange={(e) =>
              setNextOfKin({ ...nextOfKin, relationship: e.target.value })
            }
          />

          <button onClick={handleNextOfKinSubmit} className="id-submit-button">
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      )}

      {pendingMessage && <div className="kyc-message">{pendingMessage}</div>}
    </div>
  );
};

export default KycVerification;
