// import React, { useState } from "react";
// import axios from "axios";
// import "../Styles/Security.css";

// const PinReset = () => {
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [oldPin, setOldPin] = useState("");
//   const [newPin, setNewPin] = useState("");
//   const [confirmNewPin, setConfirmNewPin] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [message, setMessage] = useState(null);

//   const handlePinReset = async (e) => {
//     e.preventDefault();

//     if (newPin !== confirmNewPin) {
//       setMessage({ type: "error", text: "PINs do not match" });
//       return;
//     }

//     setIsLoading(true);
//     try {
//       // Get token from local storage
//       const token = localStorage.getItem("token");
      
//       await axios.post(
//         "https://api.ijeaweleinvest.com/users/set_transaction_pin/",
//         {
//           old_pin: oldPin,
//           new_pin: newPin,
//           confirm_pin: confirmNewPin,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           }
//         }
//       );
//       setMessage({ type: "success", text: "PIN reset successfully" });
//     } catch (error) {
//       setMessage({
//         type: "error",
//         text: error.response?.data?.message || "An error occurred",
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="accordion-item">
//       <button
//         className="accordion-button"
//         onClick={() => setIsExpanded(!isExpanded)}
//       >
//         Reset PIN
//       </button>
//       {isExpanded && (
//         <form onSubmit={handlePinReset}>
//           <input
//             type="password"
//             placeholder="Enter Old PIN"
//             value={oldPin}
//             onChange={(e) => setOldPin(e.target.value)}
//           />
//           <input
//             type="password"
//             placeholder="New PIN"
//             value={newPin}
//             onChange={(e) => setNewPin(e.target.value)}
//           />
//           <input
//             type="password"
//             placeholder="Confirm New PIN"
//             value={confirmNewPin}
//             onChange={(e) => setConfirmNewPin(e.target.value)}
//           />
//           <button type="submit" disabled={isLoading}>
//             {isLoading ? "Resetting..." : "Reset PIN"}
//           </button>
//         </form>
//       )}
//       {message && (
//         <p
//           className={`message ${
//             message.type === "error" ? "error" : "success"
//           }`}
//         >
//           {message.text}
//         </p>
//       )}
//     </div>
//   );
// };

// export default PinReset;

// import React, { useState } from "react";
// import axios from "axios";
// import "../Styles/Security.css";

// const PinReset = () => {
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [oldPin, setOldPin] = useState("");
//   const [newPin, setNewPin] = useState("");
//   const [confirmNewPin, setConfirmNewPin] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [message, setMessage] = useState(null);
//   const [pinCreated, setPinCreated] = useState(false); // New state to disable the button after PIN creation

//   const handlePinReset = async (e) => {
//     e.preventDefault();

//     if (newPin !== confirmNewPin) {
//       setMessage({ type: "error", text: "PINs do not match" });
//       return;
//     }

//     setIsLoading(true);
//     try {
//       const token = localStorage.getItem("token");

//       await axios.post(
//         "https://api.ijeaweleinvest.com/users/set_transaction_pin/",
//         {
//           old_pin: oldPin,
//           new_pin: newPin,
//           confirm_pin: confirmNewPin,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           }
//         }
//       );
      
//       setMessage({ type: "success", text: "PIN reset successfully" });
//       setPinCreated(true); // Disable button after successful PIN creation
//     } catch (error) {
//       setMessage({
//         type: "error",
//         text: error.response?.data?.message || "An error occurred",
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="accordion-item">
//       <button
//         className="accordion-button"
//         onClick={() => setIsExpanded(!isExpanded)}
//       >
//         Reset PIN
//       </button>
//       {isExpanded && (
//         <form onSubmit={handlePinReset}>
//           <input
//             type="password"
//             placeholder="Enter Old PIN"
//             value={oldPin}
//             onChange={(e) => setOldPin(e.target.value)}
//           />
//           <input
//             type="password"
//             placeholder="New PIN"
//             value={newPin}
//             onChange={(e) => setNewPin(e.target.value)}
//           />
//           <input
//             type="password"
//             placeholder="Confirm New PIN"
//             value={confirmNewPin}
//             onChange={(e) => setConfirmNewPin(e.target.value)}
//           />
//           <button type="submit" disabled={isLoading || pinCreated}>
//             {isLoading ? "Resetting..." : "Reset PIN"}
//           </button>
//         </form>
//       )}
//       {message && (
//         <p
//           className={`message ${
//             message.type === "error" ? "error" : "success"
//           }`}
//         >
//           {message.text}
//         </p>
//       )}
//     </div>
//   );
// };

// export default PinReset;


import React, { useState } from "react";
import axios from "axios";
import "../Styles/Security.css";

const PinReset = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [pinCreated, setPinCreated] = useState(false); // State to disable the button after PIN creation

  const handlePinReset = async (e) => {
    e.preventDefault();

    if (newPin !== confirmNewPin) {
      setMessage({ type: "error", text: "PINs do not match" });
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");

      await axios.post(
        "https://api.ijeaweleinvest.com/users/set_transaction_pin/",
        {
          old_pin: oldPin,
          new_pin: newPin,
          confirm_pin: confirmNewPin,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      setMessage({ type: "success", text: "PIN reset successfully" });
      setPinCreated(true); // Disable the button immediately upon success

      // Clear the success message after 3 seconds
      setTimeout(() => setMessage(null), 3000);
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "An error occurred",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="accordion-item">
      <button
        className="accordion-button"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        Reset PIN
      </button>
      {isExpanded && (
        <form onSubmit={handlePinReset}>
          <input
            type="password"
            placeholder="Enter Old PIN"
            value={oldPin}
            onChange={(e) => setOldPin(e.target.value)}
          />
          <input
            type="password"
            placeholder="New PIN"
            value={newPin}
            onChange={(e) => setNewPin(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirm New PIN"
            value={confirmNewPin}
            onChange={(e) => setConfirmNewPin(e.target.value)}
          />
          <button type="submit" disabled={isLoading || pinCreated}>
            {isLoading ? "Resetting..." : "Reset PIN"}
          </button>
        </form>
      )}
      {message && (
        <p
          className={`message ${
            message.type === "error" ? "error" : "success"
          }`}
        >
          {message.text}
        </p>
      )}
    </div>
  );
};

export default PinReset;

