// import "../Styles/BalanceOverview.css";
// import { IoMdEyeOff } from "react-icons/io";
// import { FaEye } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { useState, useEffect } from "react";
// import { usePaystackPayment } from "react-paystack";

// function BalanceOverview({ showButtons = true }) {
//   const [balanceVisibility, setBalanceVisibility] = useState(false);
//   const [userEmail, setUserEmail] = useState("");
//   const [depositAmount, setDepositAmount] = useState("");
//   const [balance, setBalance] = useState(0);
//   const [isDepositClicked, setIsDepositClicked] = useState(false);

//   // Toggle balance visibility
//   const handleEyeClick = () => {
//     setBalanceVisibility((prevVisibility) => !prevVisibility);
//   };

//   // Fetch user data and balance
//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         if (!token) {
//           throw new Error("No token found. Please log in.");
//         }

//         const response = await fetch(
//           "https://api.ijeaweleinvest.com/users/current_user/",
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const data = await response.json();
//         setUserEmail(data.email);
//         setBalance(data.wallet.balance);
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     };

//     fetchUserData();
//   }, []);

//   // Paystack configuration
//   const paystackConfig = {
//     email: userEmail,
//     amount: depositAmount * 100,
//     publicKey: "pk_live_2af1d277190e7680d0309464e7576bf4213d8464", // Live key
//   };

//   // Fetch the updated balance after a successful transaction
//   const fetchUpdatedBalance = async () => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await fetch(
//         "https://api.ijeaweleinvest.com/users/current_user/",
//         {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to fetch updated balance.");
//       }

//       const data = await response.json();
//       setBalance(data.wallet.balance);
//     } catch (error) {
//       console.error("Error fetching updated balance:", error);
//     }
//   };

//   // Poll for the updated balance until it's updated or max attempts are reached
//   const pollForUpdatedBalance = async (initialBalance, retries = 5) => {
//     const delay = 5000; // 5 seconds delay between each poll
//     let attempt = 0;

//     const poll = async () => {
//       if (attempt >= retries) {
//         console.error("Max retries reached. Stopping poll.");
//         return;
//       }

//       attempt++;
//       await fetchUpdatedBalance();
      
//       // If balance has updated, stop polling
//       if (balance !== initialBalance) {
//         console.log("Balance updated successfully.");
//         return;
//       }

//       // Retry after a delay
//       setTimeout(poll, delay);
//     };

//     poll();
//   };

//   const onSuccess = async (reference) => {
//     console.log("Payment successful", reference);

//     setIsDepositClicked(false);
//     const initialBalance = balance;
//     await pollForUpdatedBalance(initialBalance);

//     setDepositAmount("");
//   };

//   const onClose = () => {
//     console.log("Payment closed");
//   };

//   const initializePayment = usePaystackPayment(paystackConfig);

//   const handleDeposit = () => {
//     if (depositAmount > 0) {
//       initializePayment(onSuccess, onClose);
//     } else {
//       alert("Please enter a valid amount.");
//     }
//   };

//   return (
//     <div className="balance-overview">
//       <div className="balance-info">
//         <h3>Total Balance</h3>
//         <h2>{balanceVisibility ? `₦${balance}` : "*****"}</h2>
//         {showButtons && (
//           <div className="balance-actions">
//             <Link to="/withdrawal">
//               <button className="withdraw-btn">Withdraw</button>
//             </Link>

//             <button onClick={() => setIsDepositClicked(true)} className="deposit-btn">
//               Deposit
//             </button>
//           </div>
//         )}
//       </div>

//       <div className="balance-toggle">
//         <span className="balance-icon" onClick={handleEyeClick}>
//           {balanceVisibility ? <FaEye /> : <IoMdEyeOff />}
//         </span>
//       </div>

//       {isDepositClicked && (
//         <div className="overlay">
//           <div className="deposit-popup">
//             <h3>Deposit Funds</h3>
//             <input
//               type="number"
//               placeholder="Enter amount"
//               value={depositAmount}
//               onChange={(e) => setDepositAmount(Number(e.target.value))}
//               className="deposit-input"
//             />
//             <div className="popup-buttons">
//               <button onClick={handleDeposit} className="confirm-deposit-btn">
//                 Confirm Deposit
//               </button>
//               <button onClick={() => setIsDepositClicked(false)} className="cancel-btn">
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default BalanceOverview;

import "../Styles/BalanceOverview.css";
import { IoMdEyeOff } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { usePaystackPayment } from "react-paystack";

function BalanceOverview({ showButtons = true }) {
  const [balanceVisibility, setBalanceVisibility] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [balance, setBalance] = useState(0);
  const [isDepositClicked, setIsDepositClicked] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(false); // State for user verification status
  const [showMessage, setShowMessage] = useState(false); // State for showing warning message

  // Toggle balance visibility
  const handleEyeClick = () => {
    setBalanceVisibility((prevVisibility) => !prevVisibility);
  };

  // Fetch user data and balance
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found. Please log in.");
        }

        const response = await fetch(
          "https://api.ijeaweleinvest.com/users/current_user/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUserEmail(data.email);
        setBalance(data.wallet.balance);
        setVerificationStatus(data.verification_status); // Set verification status
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  // Paystack configuration
  const paystackConfig = {
    email: userEmail,
    amount: depositAmount * 100,
    publicKey: "pk_live_2af1d277190e7680d0309464e7576bf4213d8464", // Live key
  };

  // Fetch the updated balance after a successful transaction
  const fetchUpdatedBalance = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        "https://api.ijeaweleinvest.com/users/current_user/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch updated balance.");
      }

      const data = await response.json();
      setBalance(data.wallet.balance);
    } catch (error) {
      console.error("Error fetching updated balance:", error);
    }
  };

  // Poll for the updated balance until it's updated or max attempts are reached
  const pollForUpdatedBalance = async (initialBalance, retries = 5) => {
    const delay = 5000;
    let attempt = 0;

    const poll = async () => {
      if (attempt >= retries) {
        console.error("Max retries reached. Stopping poll.");
        return;
      }

      attempt++;
      await fetchUpdatedBalance();

      if (balance !== initialBalance) {
        console.log("Balance updated successfully.");
        return;
      }

      setTimeout(poll, delay);
    };

    poll();
  };

  const onSuccess = async (reference) => {
    console.log("Payment successful", reference);
    setIsDepositClicked(false);
    const initialBalance = balance;
    await pollForUpdatedBalance(initialBalance);
    setDepositAmount("");
  };

  const onClose = () => {
    console.log("Payment closed");
  };

  const initializePayment = usePaystackPayment(paystackConfig);

  const handleDeposit = () => {
    if (depositAmount > 0) {
      initializePayment(onSuccess, onClose);
    } else {
      alert("Please enter a valid amount.");
    }
  };

  const handleWithdrawClick = (e) => {
    if (!verificationStatus) {
      e.preventDefault();
      setShowMessage(true);

      setTimeout(() => setShowMessage(false), 5000);
    }
  };

  return (
    <div className="balance-overview">
      <div className="balance-info">
        <h3>Total Balance</h3>
        <h2>{balanceVisibility ? `₦${balance}` : "*****"}</h2>
        {showButtons && (
          <div className="balance-actions">
            <div className="withdrawal-btn-container" onClick={handleWithdrawClick}>
              <Link to={verificationStatus ? "/withdrawal" : "#"}>
                <button
                  className={`withdraw-btn ${!verificationStatus ? "disabled" : ""}`}
                  // disabled={!verificationStatus}
                >
                  Withdraw
                </button>
              </Link>
            </div>

            <button onClick={() => setIsDepositClicked(true)} className="deposit-btn">
              Deposit
            </button>
          </div>
        )}
      </div>

      <div className="balance-toggle">
        <span className="balance-icon" onClick={handleEyeClick}>
          {balanceVisibility ? <FaEye /> : <IoMdEyeOff />}
        </span>
      </div>

      {isDepositClicked && (
        <div className="overlay">
          <div className="deposit-popup">
            <h3>Deposit Funds</h3>
            <input
              type="number"
              placeholder="Enter amount"
              value={depositAmount}
              onChange={(e) => setDepositAmount(Number(e.target.value))}
              className="deposit-input"
            />
            <div className="popup-buttons">
              <button onClick={handleDeposit} className="confirm-deposit-btn">
                Confirm Deposit
              </button>
              <button onClick={() => setIsDepositClicked(false)} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showMessage && (
        <p className="warning-message">
          You need to get verified to be able to withdraw.
        </p>
      )}
    </div>
  );
}

export default BalanceOverview;
