import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // import useNavigate for routing
import TextInput from "../Components/TextInput";
import PasswordInput from "../Components/PasswordInput";
import axios from "axios"; // import axios for API calls
import { RevolvingDot } from "react-loader-spinner";
import "../Styles/SignUp.css";

function ForgotPassword() {
  const [isAnimated, setIsAnimated] = useState(false); // state to control animation
  const [isLoading, setIsLoading] = useState(false); //loading effect state

  // page animation
  useEffect(() => {
    setIsAnimated(true);
  }, []);

  // form validation
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [step, setStep] = useState(1); // 1: forget password, 2: reset password
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    // setIsLoading(true); // Show loader

    try {
      setIsLoading(true); // Show loader
      const response = await axios.post(
        // "http:/38.242.226.193:8000/users/forgot_password/",
        "https://api.ijeaweleinvest.com/users/forgot_password/",
        { email }
      );
      setSuccess("OTP sent to your email");
      setStep(2);
      setIsLoading(false); // Hide loader on error
    } catch (error) {
      setIsLoading(false); // Hide loader on error
      setError(error.response.data.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true); // Show loader
      const response = await axios.post(
        // "http://38.242.226.193:8000/users/reset-password/",
        "https://api.ijeaweleinvest.com/users/reset-password/",
        {
          email,
          otp,
          new_password,
          confirm_password,
        }
      );
      setSuccess("Password reset successfully");
      setIsLoading(false); // Hide loader on error
    } catch (error) {
      setIsLoading(false); // Hide loader on error
      setError(error.response.data.message);
    }
  };

  return (
    <div className="signup-container no-topnav-page">
      <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
        <h1 className="hello-text">Hello</h1>
        <h2 className="welcome-text">Recover your Account with Ease</h2>
      </div>

      <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
        <div className="form-card">
          <h2>Password Forgotten</h2>
          <p>
            To reset your password, Please enter the email associated with your
            Ijeawele Account
          </p>
          {/* step 1 */}
          {step === 1 && (
            <form onSubmit={handleForgetPassword}>
              <TextInput
                label="Email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />

              {/* page loading indication */}
              {isLoading && (
                <RevolvingDot
                  type="RevolvingDot"
                  color="#00BFFF"
                  height={5}
                  width={5}
                  timeout={3000} //3 secs
                />
              )}
              <button type="submit">Send OTP</button>
            </form>
          )}

          {/* step 2 */}
          {step === 2 && (
            <form onSubmit={handleResetPassword}>
              <TextInput
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="OTP"
              />
              <TextInput
                label="Email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
              <PasswordInput
                type="password"
                value={new_password}
                onChange={(e) => setNew_password(e.target.value)}
                placeholder="New Password"
              />
              <PasswordInput
                type="password"
                value={confirm_password}
                onChange={(e) => setConfirm_password(e.target.value)}
                placeholder="Confirm New Password"
              />

              {isLoading && (
                <RevolvingDot
                  type="RevolvingDot"
                  color="#00BFFF"
                  height={5}
                  width={5}
                  timeout={3000} //3 secs
                />
              )}

              <button type="submit">Reset Password</button>
            </form>
          )}
          {error && <p style={{ color: "red" }}>{error}</p>}
          {success && <p style={{ color: "green" }}>{success}</p>}

          <p className="create-account-text">
            Head back to Sign In? <Link to="/signin">Click Here</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
