// import React, { useState } from "react";
// import axios from "axios";
// import "../Styles/Security.css";

// const PasswordReset = () => {
//   const [email, setEmail] = useState("");
//   const [otp, setOtp] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmNewPassword, setConfirmNewPassword] = useState("");
//   const [step, setStep] = useState(1); // 1: Send OTP, 2: Reset Password
//   const [isLoading, setIsLoading] = useState(false);
//   const [message, setMessage] = useState(null);

//   const handleSendOtp = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     try {
//       await axios.post("https://api.ijeaweleinvest.com/users/forgot_password/", { email });
//       setStep(2);
//       setMessage({ type: "success", text: "OTP sent to your email" });
//     } catch (error) {
//       setMessage({ type: "error", text: error.response.data.message });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handlePasswordReset = async (e) => {
//     e.preventDefault();
//     if (newPassword !== confirmNewPassword) {
//       setMessage({ type: "error", text: "Passwords do not match" });
//       return;
//     }

//     setIsLoading(true);
//     try {
//       await axios.post("https://api.ijeaweleinvest.com/users/reset-password/", {
//         email,
//         otp,
//         new_password: newPassword,
//         confirm_password: confirmNewPassword,
//       });
//       setMessage({ type: "success", text: "Password reset successfully" });
//     } catch (error) {
//       setMessage({ type: "error", text: error.response.data.message });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="accordion-item">
//       <h2>Password Reset</h2>
//       {step === 1 ? (
//         <form onSubmit={handleSendOtp}>
//           <input
//             type="email"
//             placeholder="Enter your email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//           <button type="submit" disabled={isLoading}>
//             {isLoading ? "Sending..." : "Send OTP"}
//           </button>
//         </form>
//       ) : (
//         <form onSubmit={handlePasswordReset}>
//           <input
//             type="text"
//             placeholder="Enter OTP"
//             value={otp}
//             onChange={(e) => setOtp(e.target.value)}
//           />
//           <input
//             type="password"
//             placeholder="New Password"
//             value={newPassword}
//             onChange={(e) => setNewPassword(e.target.value)}
//           />
//           <input
//             type="password"
//             placeholder="Confirm New Password"
//             value={confirmNewPassword}
//             onChange={(e) => setConfirmNewPassword(e.target.value)}
//           />
//           <button type="submit" disabled={isLoading}>
//             {isLoading ? "Resetting..." : "Reset Password"}
//           </button>
//         </form>
//       )}
//       {message && (
//         <p className={`message ${message.type === "error" ? "error" : "success"}`}>
//           {message.text}
//         </p>
//       )}
//     </div>
//   );
// };

// export default PasswordReset;


import React, { useState } from "react";
import axios from "axios";
import "../Styles/Security.css";

const PasswordReset = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post("https://api.ijeaweleinvest.com/users/forgot_password/", { email });
      setStep(2);
      setMessage({ type: "success", text: "OTP sent to your email" });
    } catch (error) {
      setMessage({ type: "error", text: error.response.data.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setMessage({ type: "error", text: "Passwords do not match" });
      return;
    }

    setIsLoading(true);
    try {
      await axios.post("https://api.ijeaweleinvest.com/users/reset-password/", {
        email,
        otp,
        new_password: newPassword,
        confirm_password: confirmNewPassword,
      });
      setMessage({ type: "success", text: "Password reset successfully" });
    } catch (error) {
      setMessage({ type: "error", text: error.response.data.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="accordion-item">
      <button
        className="accordion-button"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        Reset Password
      </button>
      {isExpanded && (
        <>
          {step === 1 ? (
            <form onSubmit={handleSendOtp}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Sending..." : "Send OTP"}
              </button>
            </form>
          ) : (
            <form onSubmit={handlePasswordReset}>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Resetting..." : "Reset Password"}
              </button>
            </form>
          )}
          {message && (
            <p className={`message ${message.type === "error" ? "error" : "success"}`}>
              {message.text}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default PasswordReset;
