// import { Navigate } from "react-router-dom";

// // ProtectedRoute component
// function ProtectedRoute({ children }) {
//   // Check if user is authenticated by checking token existence in localStorage
//   const token = localStorage.getItem("token");

//   if (!token) {
//     // If there's no token, redirect to the SignIn page
//     return <Navigate to="/signin" />;
//   }

//   // If authenticated, render the children (protected component)
//   return children;
// };

// export default ProtectedRoute;

// ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { isTokenValid } from "../Utilities/authUtils";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");

  // If token is missing or invalid, redirect to login page
  if (!token || !isTokenValid(token)) {
    localStorage.removeItem("token"); // Clean up any invalid token
    return <Navigate to="/signin" replace />;
  }

  return children;
};

export default ProtectedRoute;
