import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import axios from "axios"; // import axios for API calls
import { CSSTransition } from "react-transition-group"; //for the transition effect
import "../Styles/Logout.css";

function Logout() {
  const navigate = useNavigate(); // Hook for redirecting the user
  const [inProp, setInProp] = useState(false); //for animation
  const logoutPageRef = useRef(null);

  useEffect(() => {
    //animation effect
    setInProp(true);
  }, []);

  // Function to handle logout logic
  const handleLogout = () => {
    // Clear token from localStorage
    localStorage.removeItem("token");

    // an API call to invalidate token on the server side
    // axios.post('http:http://38.242.226.193:8000/users/logout/',
    axios
      .post(
        "https://api.ijeaweleinvest.com/users/logout/",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("Token invalidated on server.");
      })
      .catch((error) => {
        // console.error("Server logout failed:", error);
      });

    //Redirect user to the Sign In page
    navigate("/signin");
  };

  // Cancel function to navigate the user away from the logout screen
  const handleCancel = () => {
    navigate("/overview"); // Redirect user back to the dashboard or overview page
  };

  return (
    <Layout>
      <CSSTransition
        in={inProp}
        timeout={1000}
        classNames="fade"
        nodeRef={logoutPageRef}
      >
        <div ref={logoutPageRef} className="logout-page">
          <h1>You are about to log out.</h1>
          <p>Are you sure you want to continue?</p>
          <div className="logout-buttons">
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
            <button className="cancel-button" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </CSSTransition>
    </Layout>
  );
}

export default Logout;
