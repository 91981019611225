import React from "react";
import { Link } from "react-router-dom";

const AssetCard = ({ asset }) => {
  return (
    <div>
      <Link to={`/asset/${asset.id}`}>
        <div className="asset-card">
          <img src={asset.image} alt={asset.name} />
          <h2>{asset.name}</h2>
          <p className="value">
            <span className="value">₦{asset.value.toLocaleString()}</span>
          </p>
        </div>
      </Link>
    </div>
  );
};

export default AssetCard;
