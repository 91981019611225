import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingDot from "../Components/LoadingDot";
import "../Styles/InvestmentCarousel.css";

function InvestmentCarousel() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleCount, setVisibleCount] = useState(3); // Default visible count for desktop
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("https://api.ijeaweleinvest.com/asset/", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data from the server.");
        }
        const data = await response.json();
        setInvestments(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchVehicleData();
  }, []);

  useEffect(() => {
    const updateVisibleCount = () => {
      if (window.innerWidth <= 768) {
        setVisibleCount(1); // Show only 1 card on small screens
      } else {
        setVisibleCount(3); // Show 3 cards on larger screens
      }
    };

    updateVisibleCount();
    window.addEventListener("resize", updateVisibleCount);
    return () => window.removeEventListener("resize", updateVisibleCount);
  }, []);

  const handlePrevious = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? investments.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === investments.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleContinue = () => {
    // Calculate center index based on visible count
    const centerIndex = Math.floor(visibleCount / 2);
    const activeIndex = selectedIndex + centerIndex < investments.length
      ? selectedIndex + centerIndex
      : centerIndex; // Ensure within bounds

    const activeVehicle = investments[activeIndex];
    if (activeVehicle) {
      navigate(`/asset/${activeVehicle.id}`);
    }
  };

  if (loading) {
    return <LoadingDot />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="investment-carousel">
      <h2 className="investment-title">Choose Investment</h2>
      <p className="investment-subtitle">
        Select an option below and begin your journey to financial freedom.
      </p>

      <div className="investment-carousel-container">
        <button className="carousel-arrow left-arrow" onClick={handlePrevious}>
          &lt;
        </button>

        <div className="investment-cards">
          {investments.slice(selectedIndex, selectedIndex + visibleCount).map((investment, index) => {
            // Calculate active index for center
            const isActive = index === Math.floor(visibleCount / 2);
            return (
              <div
                key={investment.id}
                className={`investment-card ${isActive ? "active" : ""}`}
              >
                <img
                  src={investment.image !== "string" ? investment.image : "/path/to/placeholder-image.png"}
                  alt={investment.name}
                  className="investment-image"
                />
                <h3>{investment.name}</h3>
                <p>₦{investment.value.toLocaleString()}</p>
              </div>
            );
          })}
        </div>

        <button className="carousel-arrow right-arrow" onClick={handleNext}>
          &gt;
        </button>
      </div>

      <button className="investment-continue-button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
}

export default InvestmentCarousel;
