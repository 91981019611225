import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import BalanceOverview from "../Components/BalanceOverview";
import TextInput from "../Components/TextInput";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import "../Styles/Withdrawal.css";
import LoadingDot from "../Components/LoadingDot";

function Withdrawal() {
  const [inProp, setInProp] = useState(false);
  const withdrawalContainerRef = useRef(null);

  // States for form fields
  const [userName, setUserName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [banks, setBanks] = useState([]);
  const [bankCode, setBankCode] = useState("");
  const [balance, setBalance] = useState(null);
  const [userid, setUserid] = useState("");
  const [pin, setPin] = useState("");

  // States for validation errors
  const [errorAccountNumber, setErrorAccountNumber] = useState("");
  const [errorAmount, setErrorAmount] = useState("");

  // Loading and confirmation state
  const [loading, setLoading] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Fetch current user data when component mounts
 useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.ijeaweleinvest.com/users/current_user/",
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const data = response.data;
        setUserid(data.id);
        setUserName(data.name);
        setBalance(data. wallet.balance);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  // Fetch bank codes when component mounts
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get(
          "https://api.ijeaweleinvest.com/wallet/fetch_banks/"
        );
        setBanks(response.data);
      } catch (error) {
        console.error("Error fetching bank codes:", error);
      }
    };
    fetchBanks();
  }, []);

  // Validation function for withdrawal form
  const validateWithdrawalForm = () => {
    setErrorAccountNumber("");
    setErrorAmount("");

    let isValid = true;

    if (accountNumber.length < 10) {
      setErrorAccountNumber("Account number must be at least 10 digits.");
      isValid = false;
    }

    if (amount > balance) {
      setErrorAmount("Withdrawal amount cannot exceed your balance.");
      isValid = false;
    }

    return isValid;
  };

  // Handle withdrawal form submission
  const handleWithdrawal = async (e) => {
    e.preventDefault();
    if (!userid) {
      console.error("User ID is undefined. Cannot proceed.");
      return;
    }

    if (!validateWithdrawalForm()) return;

    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const payload = {
        amount,
        account_number: accountNumber,
        bank_code: bankCode,
        user: userid,
        pin, // Include pin in the payload
      };

      await axios.post(
        "https://api.ijeaweleinvest.com/wallet/request_withdrawal/",
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setConfirmationMessage(
        "Withdrawal request sent successfully for approval."
      );
      setShowConfirmation(true);

      // Automatically hide the confirmation after 5 seconds
      setTimeout(() => {
        setShowConfirmation(false);
      }, 5000);

      // Reset form fields
      setAccountName("");
      setAccountNumber("");
      setAmount("");
      setBankCode("");
      setPin("");
    } catch (error) {
      console.error("Error submitting withdrawal request:", error);

      if (error.response && error.response.status === 401) {
        alert("Your session has expired. Please log in again.");
      } else {
        alert("There was an error processing your request.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <CSSTransition
        in={inProp}
        timeout={1000}
        classNames="fade"
        nodeRef={withdrawalContainerRef}
      >
        <div ref={withdrawalContainerRef} className="withdrawal-container">
          <BalanceOverview showButtons={false} />
          <div className="profile-header">
            <p>
              Account <span>Details</span>
            </p>
          </div>

          <form className="account-details" onSubmit={handleWithdrawal}>
            {/* Bank select dropdown */}
            <div className="input-wrapper">
              <label htmlFor="bankCode">Select Bank</label>
              <select
                id="bankCode"
                value={bankCode}
                onChange={(e) => setBankCode(e.target.value)}
                required
                style={{
                  border: "none",
                  height: "46px",
                  maxWidth: "370px",
                  padding: "10px",
                  borderRadius: "8px",
                  background: "#f0eeee",
                  display: "block",
                  boxSizing: "border-box",
                  width: "100%",
                }}
              >
                <option value="" disabled>
                  -- Select Bank --
                </option>
                {banks.map((bank, index) => (
                  <option key={`${bank.code}-${index}`} value={bank.code}>
                    {bank.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Account number input */}
            {/* <TextInput
              label="Account no"
              id="accountNumber"
              type="number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              error={errorAccountNumber}
            /> */}
            <TextInput
              label="Account no"
              id="accountNumber"
              type="text"
              value={accountNumber}
              onChange={(e) => {
                // Only allow numbers and limit length as needed
                const value = e.target.value.replace(/\D/g, "").slice(0, 10);
                setAccountNumber(value);
              }}
              error={errorAccountNumber}
              style={{ WebkitAppearance: "none", MozAppearance: "textfield" }}
            />

            {/* Amount input */}
            {/* <TextInput
              label="Amount"
              id="amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              error={errorAmount}
            /> */}
            <TextInput
              label="Amount"
              id="amount"
              type="text"
              value={amount}
              onChange={(e) => {
                // Only allow numbers
                const value = e.target.value.replace(/\D/g, "");
                setAmount(value);
              }}
              error={errorAmount}
              style={{ WebkitAppearance: "none", MozAppearance: "textfield" }}
            />
            {/* Pin input */}
            <TextInput
              label="Transaction PIN"
              id="pin"
              type="password"
              value={pin}
              maxLength="4"
              onChange={(e) =>
                setPin(e.target.value.replace(/\D/g, "").slice(0, 4))
              }
              error={errorAmount}
              style={{ WebkitAppearance: "none", MozAppearance: "textfield" }}
            />

            {/* Confirmation Message with CSSTransition */}
            <CSSTransition
              in={showConfirmation}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <p className="confirmation-message">{confirmationMessage}</p>
            </CSSTransition>

            {/* Loading Indicator */}
            {loading && (
              <div className="loader-overlay">
                <LoadingDot />
              </div>
            )}
            <div className="button-wrapper">
              <button
                className="withdraw-button"
                type="submit"
                disabled={loading}
              >
                Withdraw
              </button>
            </div>
          </form>
        </div>
      </CSSTransition>
    </Layout>
  );
}

export default Withdrawal;
