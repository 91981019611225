import InvestmentCarousel from "../Components/InvestmentCarousel"
import Layout from "../Components/Layout"

function Investment () {
  return (
    <Layout>
      <InvestmentCarousel/>
    </Layout>
  )
}

export default Investment