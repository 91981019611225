import React, { useState, useEffect } from "react";
import TextInput from "../Components/TextInput";
import AddressInput from "./AddressInput";
import VerificationStatus from "./VerificationStatus";
import PinCreation from "./PinCreation";
import ProfilePicture from "./ProfilePicture";
import "../Styles/PersonalInfo.css";
import Referral from "./Referral";

function PersonalInfo() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [address, setAddress] = useState("");

  // fetch user personal details
  useEffect(() => {
    // Fetch user details and check if PIN already exists
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No auth token found. Please log in.");
          return;
        }

        const response = await fetch(
          "https://api.ijeaweleinvest.com/users/current_user/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          console.error(`Error: ${response.status} ${response.statusText}`);
          return;
        }

        const data = await response.json();
        setUserId(data.id);
        setFirstName(data.first_name || "");
        setLastName(data.last_name || "");
        setEmail(data.email || "");
        setAddress(data.address || "");
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <div className="profile-content">
      <h4 className="personal-info">Personal Information</h4>
      {/* profile image */}
      <ProfilePicture 
        userId={userId}
      />

      {/* Input Fields */}
      <div className="profile-inputs">
        <TextInput
          label="First Name"
          id="firstName"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextInput
          label="Last Name"
          id="lastName"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextInput
          label="Email"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <AddressInput
          address={address}
          userIdid={userId}
          setAddress={setAddress}
        />
      </div>

      {/* PIN creation */}
      <PinCreation />

      {/* referral link */}
      <Referral/>

      {/* verification status and withdrawal btn */}
      <VerificationStatus />
    </div>
  );
}

export default PersonalInfo;
