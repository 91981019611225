import Layout from "../Components/Layout";
import TransactionList from "../Components/TransactionList";
import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from "react-transition-group";

function Transactions() {
  const [inProp, setInProp] = useState(false);
  const transactionListRef = useRef(null);

  useEffect(() => {
    setInProp(true);
  }, []);

  return (
    <Layout>
      <CSSTransition in={inProp} timeout={1000} classNames="fade" nodeRef={transactionListRef}>
        <div ref={transactionListRef}>
          <TransactionList />
        </div>
      </CSSTransition>
    </Layout>
  );
}

export default Transactions;
