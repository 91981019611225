// import { Link } from "react-router-dom";
// import "../Styles/Header.css";
// // import BellBadge from "../Assets/bell-badge.png";
// import ProfileImage from "../Assets/profile-image.png";
// import Logo from "../Assets/logo-3.svg";
// import { IoMdMenu } from "react-icons/io"; // for sidebar toggle

// function Header({ onToggleSidebar }) {
//   return (
//     <header className="header">
//       <div className="header-logo">
//         <img src={Logo} alt="Ijeawele Logo" />
//       </div>
//       <div className="right-side">
//         <div className="header-right">
//           {/* <Link to="/notification" className="notification-link">
//             <img
//               src={BellBadge}
//               alt="Notifications"
//               className="notification-icon"
//             />
//           </Link> */}
//           <Link to="/profile" className="profile-link">
//             <img src={ProfileImage} alt="Profile" className="profile-image" />
//             <span className="profile-greeting">
//               Hello, <span className="profileName">creativemyke</span>
//             </span>
//           </Link>
//         </div>

//         <div className="menu-icon" onClick={onToggleSidebar}>
//           <IoMdMenu size={30} />
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import "../Styles/Header.css";
// import ProfileImagePlaceholder from "../Assets/picture-profile-icon.jpg";
// import Logo from "../Assets/logo-3.svg";
// import { IoMdMenu } from "react-icons/io"; // for sidebar toggle

// function Header({ onToggleSidebar }) {
//   const [profileImage, setProfileImage] = useState(ProfileImagePlaceholder);
//   const [userName, setUserName] = useState("User");

//   useEffect(() => {
//     // Fetch user details (same function as in the profile page)
//     const fetchUserDetails = async () => {
//       try {
//         const token = localStorage.getItem("token");

//         if (!token) {
//           console.error("No auth token found. Please log in.");
//           return;
//         }

//         const response = await fetch("http://38.242.226.193:8000/users/current_user/", {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         });

//         if (!response.ok) {
//           console.error(`Error: ${response.status} ${response.statusText}`);
//           return;
//         }

//         const data = await response.json();
//         const fullName = `${data.first_name || "User"} ${data.last_name || ""}`;
//         setUserName(fullName);
//         setProfileImage(data.profile_image || ProfileImagePlaceholder);
//       } catch (error) {
//         console.error("Error fetching user details:", error);
//       }
//     };

//     fetchUserDetails();
//   }, []);

//   return (
//     <header className="header">
//       <div className="header-logo">
//         <img src={Logo} alt="Ijeawele Logo" />
//       </div>
//       <div className="right-side">
//         <div className="header-right">
//           {/* Profile and greeting */}
//           <Link to="/profile" className="profile-link">
//             <img src={profileImage} alt="Profile" className="profile-image" />
//             <span className="profile-greeting">
//               Hello, <span className="profileName">{userName}</span>
//             </span>
//           </Link>
//         </div>

//         <div className="menu-icon" onClick={onToggleSidebar}>
//           <IoMdMenu size={30} />
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;

// import { Link } from "react-router-dom";
// import "../Styles/Header.css";
// import ProfileImagePlaceholder from "../Assets/picture-profile-icon.jpg"; // Placeholder image
// import Logo from "../Assets/logo-3.svg";
// import { IoMdMenu } from "react-icons/io"; // for sidebar toggle
// import { useState, useEffect } from "react";

// function Header({ onToggleSidebar, updatedProfileImage }) {
//   const [profileImage, setProfileImage] = useState(ProfileImagePlaceholder);
//   const [profileName, setProfileName] = useState("creativemyke");

//   useEffect(() => {
//     const fetchUserDetails = async () => {
//       const token = localStorage.getItem("token");
//       if (!token) {
//         return;
//       }

//       try {
//         const response = await fetch(
//           "http://38.242.226.193:8000/users/current_user/",
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           setProfileImage(data.profile_image || ProfileImagePlaceholder);
//           setProfileName(`${data.first_name} ${data.last_name}`);
//         } else {
//           console.error("Failed to fetch user data.");
//         }
//       } catch (error) {
//         console.error("Error fetching user details:", error);
//       }
//     };

//     fetchUserDetails();
//   }, []);

//   // Update profile image whenever the updatedProfileImage prop changes
//   useEffect(() => {
//     if (updatedProfileImage) {
//       setProfileImage(updatedProfileImage); // Update image dynamically
//     }
//   }, [updatedProfileImage]);

//   return (
//     <header className="header">
//       <div className="header-logo">
//         <img src={Logo} alt="Ijeawele Logo" />
//       </div>
//       <div className="right-side">
//         <div className="header-right">
//           <Link to="/profile" className="profile-link">
//             <img src={profileImage} alt="Profile" className="profile-image" />
//             <span className="profile-greeting">
//               Hello, <span className="profileName">{profileName}</span>
//             </span>
//           </Link>
//         </div>

//         <div className="menu-icon" onClick={onToggleSidebar}>
//           <IoMdMenu size={30} />
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import "../Styles/Header.css";
// import ProfileImagePlaceholder from "../Assets/picture-profile-icon.jpg"; // Placeholder image
// import Logo from "../Assets/logo-3.svg";
// import { IoMdMenu } from "react-icons/io"; // for sidebar toggle

// function Header({ onToggleSidebar, updatedProfileImage }) {
//   const [profileImage, setProfileImage] = useState(ProfileImagePlaceholder);
//   const [profileName, setProfileName] = useState("user");

//   useEffect(() => {
//     const fetchUserDetails = async () => {
//       const token = localStorage.getItem("token");
//       if (!token) {
//         return;
//       }

//       try {
//         const response = await fetch(
//           // "http://38.242.226.193:8000/users/current_user/",
//           "https://api.ijeaweleinvest.com/users/current_user",
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           setProfileImage(data.profile_image || ProfileImagePlaceholder);
//           setProfileName(`${data.first_name} ${data.last_name}`);
//         } else {
//           console.error("Failed to fetch user data.");
//         }
//       } catch (error) {
//         console.error("Error fetching user details:", error);
//       }
//     };

//     fetchUserDetails();
//   }, []);

//   // Update profile image when the updatedProfileImage prop changes
//   useEffect(() => {
//     if (updatedProfileImage) {
//       setProfileImage(updatedProfileImage);
//     }
//   }, [updatedProfileImage]);

//   return (
//     <header className="header">
//       <div className="header-logo">
//         <img src={Logo} alt="Ijeawele Logo" />
//       </div>
//       <div className="right-side">
//         <div className="header-right">
//           <Link to="/profile" className="profile-link">
//             <img src={profileImage} alt="Profile" className="profile-image" />
//             <span className="profile-greeting">
//               Hello, <span className="profileName">{profileName}</span>
//             </span>
//           </Link>
//         </div>

//         <div className="menu-icon" onClick={onToggleSidebar}>
//           <IoMdMenu size={30} />
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/Header.css";
import ProfileImagePlaceholder from "../Assets/picture-profile-icon.jpg";
import Logo from "../Assets/logo-3.svg";
import { IoMdMenu } from "react-icons/io";

function Header({ onToggleSidebar, updatedProfileImage }) {
  const [profileImage, setProfileImage] = useState(localStorage.getItem("profileImage") || ProfileImagePlaceholder);
  const [profileName, setProfileName] = useState("user");

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const response = await fetch("https://api.ijeaweleinvest.com/users/current_user", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          const imageUrl = data.profile_image ? `https://api.ijeaweleinvest.com${data.profile_image}` : ProfileImagePlaceholder;
          setProfileImage(imageUrl);
          setProfileName(`${data.first_name} ${data.last_name}`);
          if (data.profile_image) {
            localStorage.setItem("profileImage", imageUrl);
          }
        } else {
          console.error("Failed to fetch user data.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (updatedProfileImage) {
      setProfileImage(updatedProfileImage);
      localStorage.setItem("profileImage", updatedProfileImage);
    }
  }, [updatedProfileImage]);

  return (
    <header className="header">
      <div className="header-logo">
        <img src={Logo} alt="Ijeawele Logo" />
      </div>
      <div className="right-side">
        <div className="header-right">
          <Link to="/profile" className="profile-link">
            <img src={profileImage} alt="Profile" className="profile-image" />
            <span className="profile-greeting">
              Hello, <span className="profileName">{profileName}</span>
            </span>
          </Link>
        </div>
        <div className="menu-icon" onClick={onToggleSidebar}>
          <IoMdMenu size={30} />
        </div>
      </div>
    </header>
  );
}

export default Header;
